import { createAsyncThunk } from '@reduxjs/toolkit';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';

export const getAlarmsList = createAsyncThunk<Array<any>, RouteParamsType, { state: RootState }>(
  'alarms/getAlarmsList',
  async (params, thunkAPI) => {
    try {
      const queryString = Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<Array<any>>(
        `https://contact-id.potsportal.com/${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching Alarms list!');
    }
  }
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import {
  DeviceBundleType,
  DeviceType,
  InventoryListItem,
  InventoryShippingType,
  ModelType,
  W2WOrderType,
} from '../../types/inventory/inventory.types';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { setModalContent, setShowModal } from '../utils/utils.slice';
import { additionalQueryParams } from '../../utils/utilities';

export const postAddATAsToInventory = createAsyncThunk<
  { success: boolean },
  {
    data: {
      warehouse: { id: number };
      model: { id: number };
      devices: Array<{ serialNumber: string; description: string; mac: string }>;
    };
  },
  { state: RootState }
>('inventory/postAddATAsToInventory', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{ success: boolean }>(`${process.env.REACT_APP_API_URL}/inventory/add-ata`, data);

    thunkAPI.dispatch(
      getInventoryList({ warehouse_id: thunkAPI.getState().warehousesReducer.microserviceWarehouseDetails.id })
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during adding inventory items!');
  }
});

export const postAddSIMsToInventory = createAsyncThunk<
  { success: boolean },
  {
    data: {
      warehouse: { id: number };
      model: { id: number };
      devices: Array<{
        description: string | null;
        serialNumber: string;
        iccid: string;
        eid: string;
        mobile: { number: string; countryCode: string } | null;
      }>;
    };
  },
  { state: RootState }
>('inventory/postAddSIMsToInventory', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{ success: boolean }>(`${process.env.REACT_APP_API_URL}/inventory/add-sim`, data);

    thunkAPI.dispatch(
      getInventoryList({ warehouse_id: thunkAPI.getState().warehousesReducer.microserviceWarehouseDetails.id })
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during adding inventory items!');
  }
});

export const postAddPowerCyclesToInventory = createAsyncThunk<
  { success: boolean },
  {
    data: {
      warehouse: { id: number };
      model: { id: number };
      devices: Array<{
        description: string | null;
        serialNumber: string;
        password: string;
      }>;
    };
  },
  { state: RootState }
>('inventory/postAddPowerCyclesToInventory', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/add-power-cycle`, data);

    thunkAPI.dispatch(
      getInventoryList({ warehouse_id: thunkAPI.getState().warehousesReducer.microserviceWarehouseDetails.id })
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during adding inventory items!');
  }
});

export const postAddRoutersToInventory = createAsyncThunk<
  { success: boolean },
  {
    data: {
      warehouse: { id: number };
      model: { id: number };
      devices: Array<{ description: string | null; serialNumber: string; imei: string }>;
    };
  },
  { state: RootState }
>('inventory/postAddRoutersToInventory', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/add-router`, data);

    thunkAPI.dispatch(
      getInventoryList({ warehouse_id: thunkAPI.getState().warehousesReducer.microserviceWarehouseDetails.id })
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during adding inventory items!');
  }
});

export const postAddBatteriesToInventory = createAsyncThunk<
  { success: boolean },
  {
    data: {
      warehouse: { id: number };
      model: { id: number };
      devices: Array<{ description: string | null; serialNumber: string }>;
    };
  },
  { state: RootState }
>('inventory/postAddBatteriesToInventory', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/add-battery`, data);

    thunkAPI.dispatch(
      getInventoryList({ warehouse_id: thunkAPI.getState().warehousesReducer.microserviceWarehouseDetails.id })
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during adding inventory items!');
  }
});

export const postAddEnclosuresToInventory = createAsyncThunk<
  { success: boolean },
  {
    data: {
      warehouse: { id: number };
      model: { id: number };
      devices: Array<{ description: string | null; serialNumber: string }>;
    };
  },
  { state: RootState }
>('inventory/postAddEnclosuresToInventory', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/add-enclosure`, data);

    thunkAPI.dispatch(
      getInventoryList({ warehouse_id: thunkAPI.getState().warehousesReducer.microserviceWarehouseDetails.id })
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during adding inventory items!');
  }
});

export const postAddDialersToInventory = createAsyncThunk<
  { success: boolean },
  {
    data: {
      warehouse: { id: number };
      model: { id: number };
      devices: Array<{
        description: string | null;
        serialNumber: string;
        csAccount: string;
        csPrimaryReceiver: { number: string; countryCode: string } | null;
        csSecondaryReceiver: { number: string; countryCode: string } | null;
      }>;
    };
  },
  { state: RootState }
>('inventory/postAddDialersToInventory', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/add-dialer`, data);

    thunkAPI.dispatch(
      getInventoryList({ warehouse_id: thunkAPI.getState().warehousesReducer.microserviceWarehouseDetails.id })
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during adding inventory items!');
  }
});

export const getInventoryList = createAsyncThunk<
  {
    atas: Array<InventoryListItem>;
    batteries: Array<InventoryListItem>;
    dialers: Array<InventoryListItem>;
    enclosures: Array<InventoryListItem>;
    powerCycles: Array<InventoryListItem>;
    routers: Array<InventoryListItem>;
    sims: Array<InventoryListItem>;
  },
  { warehouse_id?: number; org_id?: number },
  { state: RootState }
>('inventory/getInventoryList', async (data, thunkAPI) => {
  try {
    const { ...params } = data;
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<{
      atas: Array<InventoryListItem>;
      batteries: Array<InventoryListItem>;
      dialers: Array<InventoryListItem>;
      enclosures: Array<InventoryListItem>;
      powerCycles: Array<InventoryListItem>;
      routers: Array<InventoryListItem>;
      sims: Array<InventoryListItem>;
    }>(`${process.env.REACT_APP_API_URL}/inventory/get-inventory${queryString ? `?${queryString}` : ''}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching inventory!');
  }
});

export const getDevicesList = createAsyncThunk<PaginationType<DeviceType>, RouteParamsType, { state: RootState }>(
  'inventory/getDevicesList/no-loader',
  async (data, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        undefined,
        thunkAPI.getState().sharedReducer.includeSuborgs,
        thunkAPI.getState().sharedReducer.selectedCountry
      );
      const mergedParams = { ...data, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<DeviceType>>(
        `${process.env.REACT_APP_API_URL}/inventory/get-devices${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching devices!');
    }
  }
);

export const getDeviceBundlesList = createAsyncThunk<
  PaginationType<DeviceBundleType>,
  RouteParamsType,
  { state: RootState }
>('inventory/getDeviceBundlesList/no-loader', async (data, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      undefined,
      thunkAPI.getState().sharedReducer.includeSuborgs,
      thunkAPI.getState().sharedReducer.selectedCountry
    );
    const mergedParams = { ...data, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<DeviceBundleType>>(
      `${process.env.REACT_APP_API_URL}/inventory/device-bundle${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching device bundles!');
  }
});

export const deleteDeviceBundleDetails = createAsyncThunk<
  DeviceType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('inventory/deleteDeviceBundleDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<DeviceType>(`${process.env.REACT_APP_API_URL}/inventory/device-bundle/${id}`);
    if (fetchList)
      thunkAPI.dispatch(getDeviceBundlesList(thunkAPI.getState().inventoryReducer.deviceBundlesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting device bundle!');
  }
});

export const getDeviceBundleDetails = createAsyncThunk<DeviceBundleType, { id: number }, { state: RootState }>(
  'inventory/getDeviceBundleDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<DeviceBundleType>(
        `${process.env.REACT_APP_API_URL}/inventory/device-bundle/${id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching bulk details!');
    }
  }
);

export const postDeviceBundleDetails = createAsyncThunk<
  { success: boolean },
  {
    data: {
      name: string;
      description: string;
      models: Array<{ id: number }>;
      organization: null | { id: number };
    };
  },
  { state: RootState }
>('inventory/postDeviceBundleDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/device-bundle`, data);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating bundle!');
  }
});

export const patchDeviceBundleDetails = createAsyncThunk<
  { success: boolean },
  {
    id: number;
    data: {
      name: string;
      description: string;
      models: Array<{ id: number }>;
      organization: null | { id: number };
    };
  },
  { state: RootState }
>('inventory/patchDeviceBundleDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/device-bundle/${id}`, data);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating bundle!');
  }
});

export const deleteDeviceDetails = createAsyncThunk<
  DeviceType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('inventory/deleteDeviceDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<DeviceType>(`${process.env.REACT_APP_API_URL}/inventory/device/${id}`);
    if (fetchList) thunkAPI.dispatch(getDevicesList(thunkAPI.getState().inventoryReducer.devicesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting device!');
  }
});

export const getW2WOrdersList = createAsyncThunk<PaginationType<W2WOrderType>, RouteParamsType, { state: RootState }>(
  'inventory/getW2WOrdersList/no-loader',
  async (data, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        undefined,
        thunkAPI.getState().sharedReducer.includeSuborgs,
        thunkAPI.getState().sharedReducer.selectedCountry
      );
      const mergedParams = { ...data, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<W2WOrderType>>(
        `${process.env.REACT_APP_API_URL}/inventory/w2w-order${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching orders!');
    }
  }
);

export const deleteW2WOrderDetails = createAsyncThunk<
  W2WOrderType,
  { id: number; fetchList: boolean },
  {
    state: RootState;
  }
>('inventory/deleteW2WOrderDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<W2WOrderType>(`${process.env.REACT_APP_API_URL}/inventory/w2w-order/${id}`);
    if (fetchList) thunkAPI.dispatch(getW2WOrdersList(thunkAPI.getState().inventoryReducer.w2wOrdersRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting order!');
  }
});

export const postW2WOrderDetails = createAsyncThunk<
  { success: boolean },
  {
    data: {
      toWarehouse: { id: number };
      fromWarehouse: { id: number };
      items: Array<{ model: { id: number }; quantity: number }>;
    };
  },
  { state: RootState }
>('inventory/postW2WOrderDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/w2w-order`, data);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating order!');
  }
});

export const getW2WOrderDetails = createAsyncThunk<W2WOrderType, { id: number }, { state: RootState }>(
  'inventory/getW2WOrderDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<W2WOrderType>(`${process.env.REACT_APP_API_URL}/inventory/w2w-order/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching order details!');
    }
  }
);

export const patchW2WOrderDetailsApprove = createAsyncThunk<
  { success: boolean },
  { data: null; id: number },
  { state: RootState }
>('inventory/patchW2WOrderDetailsApprove', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/w2w-order/${id}/approve`, data);

    thunkAPI.dispatch(getW2WOrderDetails({ id }));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating order!');
  }
});

export const patchW2WOrderDetailsAssign = createAsyncThunk<
  { success: boolean },
  { data: { devices: Array<number> }; id: number },
  { state: RootState }
>('inventory/patchW2WOrderDetailsAssign', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/w2w-order/${id}/assign-devices`, data);

    thunkAPI.dispatch(getW2WOrderDetails({ id }));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating order!');
  }
});

export const patchW2WOrderDetailsShipping = createAsyncThunk<
  { success: boolean },
  { data: Omit<InventoryShippingType, 'carrier'> & { carrier: { id: number } }; id: number },
  { state: RootState }
>('inventory/patchW2WOrderDetailsShipping', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/w2w-order/${id}/shipping`, data);

    thunkAPI.dispatch(getW2WOrderDetails({ id }));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating order shipping details!');
  }
});

export const patchW2WOrderDetailsConfirmDelivery = createAsyncThunk<
  { success: boolean },
  { data: null; id: number },
  { state: RootState }
>('inventory/patchW2WOrderDetailsConfirmDelivery', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/inventory/w2w-order/${id}/confirm-delivery`, data);

    thunkAPI.dispatch(getW2WOrderDetails({ id }));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating order!');
  }
});

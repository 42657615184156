import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { createSlice } from '@reduxjs/toolkit';
import {
  deleteDeviceBundleDetails,
  deleteDeviceDetails,
  getDeviceBundleDetails,
  getDeviceBundlesList,
  getDevicesList,
  getInventoryList,
  getW2WOrderDetails,
  getW2WOrdersList,
  patchW2WOrderDetailsApprove,
  patchW2WOrderDetailsAssign,
  patchW2WOrderDetailsConfirmDelivery,
  patchW2WOrderDetailsShipping,
  postAddATAsToInventory,
  postAddBatteriesToInventory,
  postAddDialersToInventory,
  postAddEnclosuresToInventory,
  postAddPowerCyclesToInventory,
  postAddRoutersToInventory,
  postAddSIMsToInventory,
  postDeviceBundleDetails,
  postW2WOrderDetails,
} from './inventory.action';
import snackbarUtils from '../../utils/SnackbarUtils';
import { DeviceBundleType, DeviceType, InventoryListItem, W2WOrderType } from '../../types/inventory/inventory.types';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';

export interface InventoryState {
  addATAToInventoryPostStatus: FETCH_STATUS;
  addBatteryToInventoryPostStatus: FETCH_STATUS;
  addSIMToInventoryPostStatus: FETCH_STATUS;
  addDialerToInventoryPostStatus: FETCH_STATUS;
  addRouterToInventoryPostStatus: FETCH_STATUS;
  addEnclosureToInventoryPostStatus: FETCH_STATUS;
  addPowerCycleToInventoryPostStatus: FETCH_STATUS;
  inventoryList: null | {
    atas: Array<InventoryListItem>;
    batteries: Array<InventoryListItem>;
    dialers: Array<InventoryListItem>;
    enclosures: Array<InventoryListItem>;
    powerCycles: Array<InventoryListItem>;
    routers: Array<InventoryListItem>;
    sims: Array<InventoryListItem>;
  };
  inventoryListFetchStatus: FETCH_STATUS;
  deviceBundlesRouteParams: RouteParamsType;
  deviceBundleDetails: null | DeviceBundleType;
  deviceBundleDetailsFetchStatus: FETCH_STATUS;
  deviceBundlesList: null | PaginationType<DeviceBundleType>;
  deviceBundlesListFetchStatus: FETCH_STATUS;
  deviceBundleDetailsPostStatus: FETCH_STATUS;
  deviceBundleDetailsDeleteStatus: FETCH_STATUS;
  deviceBundleDetailsPatchStatus: FETCH_STATUS;
  devicesList: null | PaginationType<DeviceType>;
  devicesListFetchStatus: FETCH_STATUS;
  devicesRouteParams: RouteParamsType;
  deviceDetailsDeleteStatus: FETCH_STATUS;
  w2wOrdersList: null | PaginationType<W2WOrderType>;
  w2wOrdersListFetchStatus: FETCH_STATUS;
  w2wOrdersRouteParams: RouteParamsType;
  w2wOrderDetailsDeleteStatus: FETCH_STATUS;
  w2wOrderDetailsPostStatus: FETCH_STATUS;
  w2wOrderDetailsApprovePatchStatus: FETCH_STATUS;
  w2wOrderDetailsAssignPatchStatus: FETCH_STATUS;
  w2wOrderDetailsFetchStatus: FETCH_STATUS;
  w2wOrderDetails: null | W2WOrderType;
  w2wOrderDetailsShippingPatchStatus: FETCH_STATUS;
  w2wOrderDetailsConfirmDeliveryPatchStatus: FETCH_STATUS;
}

export const initialDevicesRouteParams = {
  page: 1,
  limit: 10,
  order: '-obj.id',
  q: '',
  category: 'Ata',
};

export const initialW2WOrdersRouteParams = {
  page: 1,
  limit: 10,
  order: '-obj.id',
  q: '',
};

export const initialDeviceBundlesRouteParams = {
  page: 1,
  limit: 10,
  order: '-obj.id',
  q: '',
};

const INIT_STATE: InventoryState = {
  addATAToInventoryPostStatus: FETCH_STATUS.NULL,
  addBatteryToInventoryPostStatus: FETCH_STATUS.NULL,
  addSIMToInventoryPostStatus: FETCH_STATUS.NULL,
  addDialerToInventoryPostStatus: FETCH_STATUS.NULL,
  addRouterToInventoryPostStatus: FETCH_STATUS.NULL,
  addEnclosureToInventoryPostStatus: FETCH_STATUS.NULL,
  addPowerCycleToInventoryPostStatus: FETCH_STATUS.NULL,
  inventoryList: null,
  inventoryListFetchStatus: FETCH_STATUS.NULL,
  deviceBundlesRouteParams: initialDeviceBundlesRouteParams,
  deviceBundleDetails: null,
  deviceBundleDetailsFetchStatus: FETCH_STATUS.NULL,
  deviceBundlesList: null,
  deviceBundlesListFetchStatus: FETCH_STATUS.NULL,
  deviceBundleDetailsPostStatus: FETCH_STATUS.NULL,
  deviceBundleDetailsDeleteStatus: FETCH_STATUS.NULL,
  deviceBundleDetailsPatchStatus: FETCH_STATUS.NULL,
  devicesList: null,
  devicesListFetchStatus: FETCH_STATUS.NULL,
  devicesRouteParams: initialDevicesRouteParams,
  deviceDetailsDeleteStatus: FETCH_STATUS.NULL,
  w2wOrdersList: null,
  w2wOrdersListFetchStatus: FETCH_STATUS.NULL,
  w2wOrdersRouteParams: initialW2WOrdersRouteParams,
  w2wOrderDetailsDeleteStatus: FETCH_STATUS.NULL,
  w2wOrderDetailsPostStatus: FETCH_STATUS.NULL,
  w2wOrderDetailsApprovePatchStatus: FETCH_STATUS.NULL,
  w2wOrderDetailsAssignPatchStatus: FETCH_STATUS.NULL,
  w2wOrderDetailsFetchStatus: FETCH_STATUS.NULL,
  w2wOrderDetails: null,
  w2wOrderDetailsShippingPatchStatus: FETCH_STATUS.NULL,
  w2wOrderDetailsConfirmDeliveryPatchStatus: FETCH_STATUS.NULL,
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState: INIT_STATE,
  reducers: {
    setAddATAToInventoryPostStatus: (state, action) => {
      state.addATAToInventoryPostStatus = action.payload;
    },
    setAddBatteryToInventoryPostStatus: (state, action) => {
      state.addBatteryToInventoryPostStatus = action.payload;
    },
    setAddSIMToInventoryPostStatus: (state, action) => {
      state.addSIMToInventoryPostStatus = action.payload;
    },
    setAddDialerToInventoryPostStatus: (state, action) => {
      state.addDialerToInventoryPostStatus = action.payload;
    },
    setAddRouterToInventoryPostStatus: (state, action) => {
      state.addRouterToInventoryPostStatus = action.payload;
    },
    setAddEnclosureToInventoryPostStatus: (state, action) => {
      state.addEnclosureToInventoryPostStatus = action.payload;
    },
    setAddPowerCycleToInventoryPostStatus: (state, action) => {
      state.addPowerCycleToInventoryPostStatus = action.payload;
    },
    setInventoryList: (state, action) => {
      state.inventoryList = action.payload;
    },
    setInventoryListFetchStatus: (state, action) => {
      state.inventoryListFetchStatus = action.payload;
    },
    setDeviceBundlesList: (state, action) => {
      state.deviceBundlesList = action.payload;
    },
    setDeviceBundlesListFetchStatus: (state, action) => {
      state.deviceBundlesListFetchStatus = action.payload;
    },
    setDeviceBundleDetailsPostStatus: (state, action) => {
      state.deviceBundleDetailsPostStatus = action.payload;
    },
    setDeviceBundleDetails: (state, action) => {
      state.deviceBundleDetails = action.payload;
    },
    setDeviceBundleDetailsFetchStatus: (state, action) => {
      state.deviceBundleDetailsFetchStatus = action.payload;
    },
    setDeviceBundleDetailsPatchStatus: (state, action) => {
      state.deviceBundleDetailsPatchStatus = action.payload;
    },
    setDeviceBundleDetailsDeleteStatus: (state, action) => {
      state.deviceBundleDetailsDeleteStatus = action.payload;
    },
    setDevicesList: (state, action) => {
      state.devicesList = action.payload;
    },
    setDevicesListFetchStatus: (state, action) => {
      state.devicesListFetchStatus = action.payload;
    },
    setW2WOrdersList: (state, action) => {
      state.w2wOrdersList = action.payload;
    },
    setW2WOrdersListFetchStatus: (state, action) => {
      state.w2wOrdersListFetchStatus = action.payload;
    },
    setDeviceDetailsDeleteStatus: (state, action) => {
      state.deviceDetailsDeleteStatus = action.payload;
    },
    setW2WOrderDetailsDeleteStatus: (state, action) => {
      state.w2wOrderDetailsDeleteStatus = action.payload;
    },
    setW2WOrderDetailsPostStatus: (state, action) => {
      state.w2wOrderDetailsPostStatus = action.payload;
    },
    setW2WOrderDetails: (state, action) => {
      state.w2wOrderDetails = action.payload;
    },
    setW2WOrderDetailsFetchStatus: (state, action) => {
      state.w2wOrderDetailsFetchStatus = action.payload;
    },
    setW2WOrderDetailsApprovePatchStatus: (state, action) => {
      state.w2wOrderDetailsApprovePatchStatus = action.payload;
    },
    setW2WOrderDetailsAssignPatchStatus: (state, action) => {
      state.w2wOrderDetailsAssignPatchStatus = action.payload;
    },
    setW2WOrderDetailsShippingPatchStatus: (state, action) => {
      state.w2wOrderDetailsShippingPatchStatus = action.payload;
    },
    setW2WOrderDetailsConfirmDeliveryPatchStatus: (state, action) => {
      state.w2wOrderDetailsConfirmDeliveryPatchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'inventory-devices') {
        state.devicesRouteParams = { ...state.devicesRouteParams, ...payload.data };
      }

      if (payload.reducer === 'inventory-w2w-orders') {
        state.w2wOrdersRouteParams = { ...state.w2wOrdersRouteParams, ...payload.data };
      }

      if (payload.reducer === 'inventory-device-bundles') {
        state.deviceBundlesRouteParams = { ...state.deviceBundlesRouteParams, ...payload.data };
      }
    });

    // POST ADD ATA TO INVENTORY
    builder.addCase(postAddATAsToInventory.pending, state => {
      state.addATAToInventoryPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postAddATAsToInventory.rejected, (state, action) => {
      state.addATAToInventoryPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postAddATAsToInventory.fulfilled, state => {
      state.addATAToInventoryPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Inventory items were added successfully!'));
    });

    // POST ADD BATTERY TO INVENTORY
    builder.addCase(postAddBatteriesToInventory.pending, state => {
      state.addBatteryToInventoryPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postAddBatteriesToInventory.rejected, (state, action) => {
      state.addBatteryToInventoryPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postAddBatteriesToInventory.fulfilled, state => {
      state.addBatteryToInventoryPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Inventory items were added successfully!'));
    });

    // POST ADD SIM TO INVENTORY
    builder.addCase(postAddSIMsToInventory.pending, state => {
      state.addSIMToInventoryPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postAddSIMsToInventory.rejected, (state, action) => {
      state.addSIMToInventoryPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postAddSIMsToInventory.fulfilled, state => {
      state.addSIMToInventoryPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Inventory items were added successfully!'));
    });

    // POST ADD DIALER TO INVENTORY
    builder.addCase(postAddDialersToInventory.pending, state => {
      state.addDialerToInventoryPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postAddDialersToInventory.rejected, (state, action) => {
      state.addDialerToInventoryPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postAddDialersToInventory.fulfilled, state => {
      state.addDialerToInventoryPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Inventory items were added successfully!'));
    });

    // POST ADD ROUTER TO INVENTORY
    builder.addCase(postAddRoutersToInventory.pending, state => {
      state.addRouterToInventoryPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postAddRoutersToInventory.rejected, (state, action) => {
      state.addRouterToInventoryPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postAddRoutersToInventory.fulfilled, state => {
      state.addRouterToInventoryPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Inventory items were added successfully!'));
    });

    // POST ADD ENCLOSURE TO INVENTORY
    builder.addCase(postAddEnclosuresToInventory.pending, state => {
      state.addEnclosureToInventoryPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postAddEnclosuresToInventory.rejected, (state, action) => {
      state.addEnclosureToInventoryPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postAddEnclosuresToInventory.fulfilled, state => {
      state.addEnclosureToInventoryPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Inventory items were added successfully!'));
    });

    // POST ADD POWERCYCLES TO INVENTORY
    builder.addCase(postAddPowerCyclesToInventory.pending, state => {
      state.addPowerCycleToInventoryPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postAddPowerCyclesToInventory.rejected, (state, action) => {
      state.addPowerCycleToInventoryPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postAddPowerCyclesToInventory.fulfilled, state => {
      state.addPowerCycleToInventoryPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Inventory items were added successfully!'));
    });

    // GET INVENTORY
    builder.addCase(getInventoryList.pending, state => {
      state.inventoryListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getInventoryList.rejected, (state, action) => {
      if (action.payload) {
        state.inventoryListFetchStatus = FETCH_STATUS.REJECTED;
        // state.ataBaseTemplatesList = null;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getInventoryList.fulfilled, (state, action) => {
      state.inventoryListFetchStatus = FETCH_STATUS.FULFILLED;
      state.inventoryList = action.payload;
    });

    // GET INVENTORY
    builder.addCase(getDevicesList.pending, state => {
      state.devicesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDevicesList.rejected, (state, action) => {
      if (action.payload) {
        state.devicesListFetchStatus = FETCH_STATUS.REJECTED;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getDevicesList.fulfilled, (state, action) => {
      state.devicesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.devicesList = action.payload;
    });

    // GET DEVICE
    builder.addCase(getDeviceBundlesList.pending, state => {
      state.deviceBundlesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDeviceBundlesList.rejected, (state, action) => {
      if (action.payload) {
        state.deviceBundlesListFetchStatus = FETCH_STATUS.REJECTED;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getDeviceBundlesList.fulfilled, (state, action) => {
      state.deviceBundlesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.deviceBundlesList = action.payload;
    });

    // DELETE DEVICE DETAILS
    builder.addCase(deleteDeviceDetails.pending, state => {
      state.deviceDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteDeviceDetails.rejected, (state, action) => {
      state.deviceDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteDeviceDetails.fulfilled, state => {
      state.deviceDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Device deleted successfully!');
      });
    });

    // GET ORDERS
    builder.addCase(getW2WOrdersList.pending, state => {
      state.w2wOrdersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getW2WOrdersList.rejected, (state, action) => {
      if (action.payload) {
        state.w2wOrdersListFetchStatus = FETCH_STATUS.REJECTED;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getW2WOrdersList.fulfilled, (state, action) => {
      state.w2wOrdersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.w2wOrdersList = action.payload;
    });

    // POST ADD ROUTER TO INVENTORY
    builder.addCase(postW2WOrderDetails.pending, state => {
      state.w2wOrderDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postW2WOrderDetails.rejected, (state, action) => {
      state.w2wOrderDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postW2WOrderDetails.fulfilled, state => {
      state.w2wOrderDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('The order was created successfully!'));
    });

    // POST DEVICE BULK
    builder.addCase(postDeviceBundleDetails.pending, state => {
      state.deviceBundleDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postDeviceBundleDetails.rejected, (state, action) => {
      state.deviceBundleDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postDeviceBundleDetails.fulfilled, state => {
      state.deviceBundleDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('The bulk was created successfully!'));
    });

    // DELETE DEVICE BULK
    builder.addCase(deleteDeviceBundleDetails.pending, state => {
      state.deviceBundleDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteDeviceBundleDetails.rejected, (state, action) => {
      state.deviceBundleDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteDeviceBundleDetails.fulfilled, state => {
      state.deviceBundleDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Bundle deleted successfully!'));
    });

    // GET DEVICE DETAILS
    builder.addCase(getDeviceBundleDetails.pending, state => {
      state.deviceBundleDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDeviceBundleDetails.rejected, (state, action) => {
      if (action.payload) {
        state.deviceBundleDetailsFetchStatus = FETCH_STATUS.REJECTED;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getDeviceBundleDetails.fulfilled, (state, action) => {
      state.deviceBundleDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.deviceBundleDetails = action.payload;
    });

    // GET ORDER DETAILS
    builder.addCase(getW2WOrderDetails.pending, state => {
      state.w2wOrderDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getW2WOrderDetails.rejected, (state, action) => {
      if (action.payload) {
        state.w2wOrderDetailsFetchStatus = FETCH_STATUS.REJECTED;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getW2WOrderDetails.fulfilled, (state, action) => {
      state.w2wOrderDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.w2wOrderDetails = action.payload;
    });

    // PATCH APPROVE
    builder.addCase(patchW2WOrderDetailsApprove.pending, state => {
      state.w2wOrderDetailsApprovePatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchW2WOrderDetailsApprove.rejected, (state, action) => {
      state.w2wOrderDetailsApprovePatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchW2WOrderDetailsApprove.fulfilled, state => {
      state.w2wOrderDetailsApprovePatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('The order was approved successfully!'));
    });

    // PATCH ASSIGN
    builder.addCase(patchW2WOrderDetailsAssign.pending, state => {
      state.w2wOrderDetailsAssignPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchW2WOrderDetailsAssign.rejected, (state, action) => {
      state.w2wOrderDetailsAssignPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchW2WOrderDetailsAssign.fulfilled, state => {
      state.w2wOrderDetailsAssignPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Devices have been assigned!'));
    });

    // PATCH SHIPPING
    builder.addCase(patchW2WOrderDetailsShipping.pending, state => {
      state.w2wOrderDetailsShippingPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchW2WOrderDetailsShipping.rejected, (state, action) => {
      state.w2wOrderDetailsShippingPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchW2WOrderDetailsShipping.fulfilled, state => {
      state.w2wOrderDetailsShippingPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('The order shipping details was saved successfully!'));
    });

    // PATCH CONFIRM DETAILS
    builder.addCase(patchW2WOrderDetailsConfirmDelivery.pending, state => {
      state.w2wOrderDetailsConfirmDeliveryPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchW2WOrderDetailsConfirmDelivery.rejected, (state, action) => {
      state.w2wOrderDetailsConfirmDeliveryPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchW2WOrderDetailsConfirmDelivery.fulfilled, state => {
      state.w2wOrderDetailsConfirmDeliveryPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('The order was updated successfully!'));
    });
  },
});

export const {
  setAddATAToInventoryPostStatus,
  setAddDialerToInventoryPostStatus,
  setAddPowerCycleToInventoryPostStatus,
  setAddEnclosureToInventoryPostStatus,
  setAddRouterToInventoryPostStatus,
  setAddSIMToInventoryPostStatus,
  setAddBatteryToInventoryPostStatus,
  setInventoryListFetchStatus,
  setInventoryList,
  setDevicesListFetchStatus,
  setDevicesList,
  setDeviceDetailsDeleteStatus,
  setW2WOrdersListFetchStatus,
  setW2WOrdersList,
  setW2WOrderDetailsDeleteStatus,
  setW2WOrderDetailsPostStatus,
  setW2WOrderDetailsFetchStatus,
  setW2WOrderDetails,
  setW2WOrderDetailsApprovePatchStatus,
  setW2WOrderDetailsAssignPatchStatus,
  setW2WOrderDetailsShippingPatchStatus,
  setW2WOrderDetailsConfirmDeliveryPatchStatus,
  setDeviceBundlesList,
  setDeviceBundlesListFetchStatus,
  setDeviceBundleDetailsPatchStatus,
  setDeviceBundleDetailsPostStatus,
  setDeviceBundleDetailsDeleteStatus,
  setDeviceBundleDetailsFetchStatus,
  setDeviceBundleDetails,
} = inventorySlice.actions;

export default inventorySlice.reducer;

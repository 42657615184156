import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { TicketType } from '../../types/ticket/ticket.type';
import { createSlice } from '@reduxjs/toolkit';
import { UserType } from '../../types/user/user.type';
import { TicketListItemType } from '../../types/ticket/TicketListItem.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteAttachmentDetails,
  deleteTicketDetails,
  getTicketDetails,
  getTicketsList,
  getTicketUsersList,
  patchTicketDetails,
  postAttachmentDetails,
  postCommentDetails,
  postTicketDetails,
  postTicketNoteDetails,
} from './tickets.action';

export interface TicketsState {
  ticketsList: null | PaginationType<TicketListItemType>;
  ticketsListFetchStatus: FETCH_STATUS;
  ticketsRouteParams: RouteParamsType;
  ticketDetails: null | TicketType;
  ticketDetailsFetchStatus: FETCH_STATUS;
  ticketDetailsPostStatus: FETCH_STATUS;
  ticketDetailsPatchStatus: FETCH_STATUS;
  ticketDetailsDeleteStatus: FETCH_STATUS;
  ticketUsersList: null | { items: Array<UserType> };
  ticketUsersListFetchStatus: FETCH_STATUS;
  commentDetailsPostStatus: FETCH_STATUS;
  noteDetailsPostStatus: FETCH_STATUS;
  attachmentDetailsPostStatus: FETCH_STATUS;
  attachmentDetailsDeleteStatus: FETCH_STATUS;
}

export const initialTicketsRouteParams = {
  page: 1,
  limit: 10,
  order: '-tickets.id',
  q: '',
  priority: '',
  type: '',
  status: '',
};

const INIT_STATE: TicketsState = {
  ticketsRouteParams: initialTicketsRouteParams,
  ticketsList: null,
  ticketsListFetchStatus: FETCH_STATUS.NULL,
  ticketDetails: null,
  ticketDetailsFetchStatus: FETCH_STATUS.NULL,
  ticketDetailsPostStatus: FETCH_STATUS.NULL,
  ticketDetailsPatchStatus: FETCH_STATUS.NULL,
  ticketDetailsDeleteStatus: FETCH_STATUS.NULL,
  ticketUsersList: null,
  ticketUsersListFetchStatus: FETCH_STATUS.NULL,
  commentDetailsPostStatus: FETCH_STATUS.NULL,
  noteDetailsPostStatus: FETCH_STATUS.NULL,
  attachmentDetailsPostStatus: FETCH_STATUS.NULL,
  attachmentDetailsDeleteStatus: FETCH_STATUS.NULL,
};

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: INIT_STATE,
  reducers: {
    setTicketsList: (state, action) => {
      state.ticketsList = action.payload;
    },
    setTicketsListFetchStatus: (state, action) => {
      state.ticketsListFetchStatus = action.payload;
    },
    setTicketDetails: (state, action) => {
      state.ticketDetails = action.payload;
    },
    setTicketDetailsFetchStatus: (state, action) => {
      state.ticketDetailsFetchStatus = action.payload;
    },
    setTicketDetailsPostStatus: (state, action) => {
      state.ticketDetailsPostStatus = action.payload;
    },
    setTicketDetailsPatchStatus: (state, action) => {
      state.ticketDetailsPatchStatus = action.payload;
    },
    setTicketDetailsDeleteStatus: (state, action) => {
      state.ticketDetailsDeleteStatus = action.payload;
    },
    setTicketUsersList: (state, action) => {
      state.ticketUsersList = action.payload;
    },
    setTicketUsersListFetchStatus: (state, action) => {
      state.ticketUsersListFetchStatus = action.payload;
    },
    setCommentDetailsPostStatus: (state, action) => {
      state.commentDetailsPostStatus = action.payload;
    },
    setAttachmentDetailsPostStatus: (state, action) => {
      state.attachmentDetailsPostStatus = action.payload;
    },
    setAttachmentDetailsDeleteStatus: (state, action) => {
      state.attachmentDetailsDeleteStatus = action.payload;
    },
    setNoteDetailsPostStatus: (state, action) => {
      state.noteDetailsPostStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'tickets') {
        state.ticketsRouteParams = { ...state.ticketsRouteParams, ...payload.data };
      }
    });

    // CREATE TICKETS DETAILS
    builder.addCase(postTicketDetails.pending, state => {
      state.ticketDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postTicketDetails.rejected, (state, action) => {
      state.ticketDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postTicketDetails.fulfilled, state => {
      state.ticketDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Ticket has been opened!');
      });
    });

    // GET TICKETS LIST
    builder.addCase(getTicketsList.pending, state => {
      state.ticketsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getTicketsList.rejected, (state, action) => {
      if (action.payload) {
        state.ticketsListFetchStatus = FETCH_STATUS.REJECTED;
        // state.ticketsList = null;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getTicketsList.fulfilled, (state, action) => {
      state.ticketsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.ticketsList = action.payload;
    });

    // GET TICKETS DETAILS
    builder.addCase(getTicketDetails.pending, state => {
      state.ticketDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getTicketDetails.rejected, (state, action) => {
      state.ticketDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.ticketDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getTicketDetails.fulfilled, (state, action) => {
      state.ticketDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.ticketDetails = action.payload;
    });

    // PATCH TICKETS DETAILS
    builder.addCase(patchTicketDetails.pending, state => {
      state.ticketDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchTicketDetails.rejected, (state, action) => {
      state.ticketDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchTicketDetails.fulfilled, state => {
      state.ticketDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Tickets updated successfully');
      });
    });

    // DELETE TICKETS DETAILS
    builder.addCase(deleteTicketDetails.pending, state => {
      state.ticketDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteTicketDetails.rejected, (state, action) => {
      state.ticketDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteTicketDetails.fulfilled, state => {
      state.ticketDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Tickets deleted successfully');
      });
    });

    // GET TICKET USERS LIST
    builder.addCase(getTicketUsersList.pending, state => {
      state.ticketUsersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getTicketUsersList.rejected, (state, action) => {
      state.ticketUsersListFetchStatus = FETCH_STATUS.REJECTED;
      state.ticketUsersList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getTicketUsersList.fulfilled, (state, action) => {
      state.ticketUsersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.ticketUsersList = action.payload;
    });

    // POST COMMENT
    builder.addCase(postCommentDetails.pending, state => {
      state.commentDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postCommentDetails.rejected, (state, action) => {
      state.commentDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postCommentDetails.fulfilled, state => {
      state.commentDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Comment has been added!'));
    });

    // POST NOTE
    builder.addCase(postTicketNoteDetails.pending, state => {
      state.noteDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postTicketNoteDetails.rejected, (state, action) => {
      state.noteDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postTicketNoteDetails.fulfilled, state => {
      state.noteDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Note has been added!'));
    });

    // POST ATTACHMENT
    builder.addCase(postAttachmentDetails.pending, state => {
      state.commentDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postAttachmentDetails.rejected, (state, action) => {
      state.commentDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postAttachmentDetails.fulfilled, state => {
      state.commentDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Attachment was added successfully!'));
    });

    // DELETE ATTACHMENT
    builder.addCase(deleteAttachmentDetails.pending, state => {
      state.attachmentDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteAttachmentDetails.rejected, (state, action) => {
      state.attachmentDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteAttachmentDetails.fulfilled, state => {
      state.attachmentDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Attachment has been removed!'));
    });
  },
});

export const {
  setTicketDetailsDeleteStatus,
  setTicketsListFetchStatus,
  setTicketDetailsFetchStatus,
  setTicketDetailsPatchStatus,
  setTicketDetailsPostStatus,
  setTicketsList,
  setTicketDetails,
  setTicketUsersListFetchStatus,
  setTicketUsersList,
  setCommentDetailsPostStatus,
  setAttachmentDetailsDeleteStatus,
  setAttachmentDetailsPostStatus,
  setNoteDetailsPostStatus,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;

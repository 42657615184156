import { lazy } from 'react';

const AlarmsList = lazy(() => import('../views/Alarms/AlarmsList/AlarmsList'));

export const AlarmsRoutes = [
  {
    path: '/alarms',
    name: 'Alarms',
    icon: 'mdi mdi-account-key',
    component: AlarmsList,
  },
];

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setModalContent, setShowModal } from '../utils/utils.slice';
import { CountryType } from '../../types/country/country.type';
import { getOrganizationDetails } from '../organizations/organizations.action';

export const postCountryDetails = createAsyncThunk<CountryType, { data: unknown }, { state: RootState }>(
  'countries/postCountryDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<CountryType>(`${process.env.REACT_APP_API_URL}/countries`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating country!');
    }
  }
);

export const getCountriesList = createAsyncThunk<
  PaginationType<CountryType>,
  RouteParamsType & {
    controller: AbortController;
  },
  { state: RootState }
>('countries/getCountriesList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<CountryType>>(
      `${process.env.REACT_APP_API_URL}/countries${queryString ? `?${queryString}` : ''}`,
      { signal: controller.signal }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching countries!');
  }
});

export const getCountryDetails = createAsyncThunk<CountryType, { id: number }, { state: RootState }>(
  'countries/getCountryDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<CountryType>(`${process.env.REACT_APP_API_URL}/countries/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching country!');
    }
  }
);

export const patchCountryDetails = createAsyncThunk<CountryType, { id: number; data: unknown }, { state: RootState }>(
  'countries/patchCountryDetails',
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.patch<CountryType>(`${process.env.REACT_APP_API_URL}/countries/${id}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating country!');
    }
  }
);

export const deleteCountryDetails = createAsyncThunk<
  CountryType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('countries/deleteCountryDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<CountryType>(`${process.env.REACT_APP_API_URL}/countries/${id}`);
    if (fetchList) thunkAPI.dispatch(getCountriesList(thunkAPI.getState().countriesReducer.countriesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting country!');
  }
});

export const patchEnableCountry = createAsyncThunk<CountryType, { id: number; org_id: number }, { state: RootState }>(
  'countries/patchEnableCountry',
  async ({ org_id, id }, thunkAPI) => {
    try {
      const response = await axios.patch<CountryType>(
        `${process.env.REACT_APP_API_URL}/organizations/${org_id}/enableCountry/${id}`,
        {}
      );
      thunkAPI.dispatch(getOrganizationDetails({ id: org_id }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during enabling country!');
    }
  }
);

export const patchDisableCountry = createAsyncThunk<CountryType, { id: number; org_id: number }, { state: RootState }>(
  'countries/patchDisableCountry',
  async ({ org_id, id }, thunkAPI) => {
    try {
      const response = await axios.patch<CountryType>(
        `${process.env.REACT_APP_API_URL}/organizations/${org_id}/disableCountry/${id}`,
        {}
      );
      thunkAPI.dispatch(getOrganizationDetails({ id: org_id }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during disabling country!');
    }
  }
);

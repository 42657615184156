import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import {
  AtaPortContactIdOverrideType,
  ContactIDMessageListItemType,
  CreateAtaPortContactIdOverrideDto,
} from '../../types/contact-id-message/contact-id-message.type';
import { additionalQueryParams } from '../../utils/utilities';

export const getATAContactIDMessages = createAsyncThunk<
  Array<ContactIDMessageListItemType>,
  { mac: string; key: string },
  { state: RootState }
>('contactIDMessagesReducer/getATAContactIDMessages', async ({ mac, key }, thunkAPI) => {
  try {
    const response = await axios.get<Array<ContactIDMessageListItemType>>(
      `${process.env.REACT_APP_API_URL}/contact-id/list?mac=${mac}&key=${key}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching contact ID messages!');
  }
});

export const getContactIDMessages = createAsyncThunk<
  PaginationType<ContactIDMessageListItemType>,
  RouteParamsType & { controller: AbortController },
  { state: RootState }
>('contactIDMessagesReducer/getContactIDMessages/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<ContactIDMessageListItemType>>(
      `${process.env.REACT_APP_API_URL}/contact-id${queryString ? `?${queryString}` : ''}`,
      { signal: controller.signal }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching contact ID messages!');
  }
});

export const patchContactIDOverrides = createAsyncThunk<
  { success: boolean },
  { data: CreateAtaPortContactIdOverrideDto },
  { state: RootState }
>('contactIDMessagesReducer/patchContactIDOverrides', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/contact-id/overrides`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating contact ID overrides!');
  }
});

export const getContactIDOverrides = createAsyncThunk<
  AtaPortContactIdOverrideType[],
  { ataId: number },
  {
    state: RootState;
  }
>('contactIDMessagesReducer/getContactIDOverrides', async ({ ataId }, thunkAPI) => {
  try {
    const response = await axios.get<AtaPortContactIdOverrideType[]>(
      `${process.env.REACT_APP_API_URL}/contact-id/overrides?ataId=${ataId}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating contact ID overrides!');
  }
});

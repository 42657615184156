import { configureStore } from '@reduxjs/toolkit';
import reducers from './Reducers';
import { persistReducer, persistStore } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';

const saveSharedFilters = createFilter(
  'sharedReducer',
  ['selectedCompany', 'includeSuborgs', 'selectedLocation', 'selectedCountry', 'selectedUserAccess'],
  ['selectedCompany', 'includeSuborgs', 'selectedLocation', 'selectedCountry', 'selectedUserAccess']
);

const persistConfig = {
  key: 'shared',
  storage,
  whitelist: ['sharedReducer'],
  transforms: [saveSharedFilters],
  stateReconciler: (persistedState, storeState) => {
    if (persistedState?.sharedReducer) {
      return {
        ...storeState,
        sharedReducer: {
          ...storeState.sharedReducer,
          ...persistedState.sharedReducer,
        },
      };
    }

    return {
      ...storeState,
    };
  },
};

const pReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: pReducer,
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      thunk: {
        extraArgument: '',
      },
    }),
});
export const persistor = persistStore(store);

export type DispatchAction<T> = {
  type: string;
  payload: T;
};
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

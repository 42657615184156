import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { NotificationListItemType } from '../../types/notification/notification.type';
import { additionalQueryParams } from '../../utils/utilities';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';

export const getNotificationsList = createAsyncThunk<
  PaginationType<NotificationListItemType>,
  RouteParamsType & { controller: AbortController },
  {
    state: RootState;
  }
>('notifications/getNotificationsList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;

    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<NotificationListItemType>>(
      `${process.env.REACT_APP_API_URL}/notifications${queryString ? `?${queryString}` : ''}`,
      { signal: controller?.signal }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching alerts!');
  }
});

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncTaskType } from '../../types/async-task/async-task.type';
import { RootState } from '../../redux/Store';
import {
  CreateDialerDto,
  DialerDetailsType,
  DialerListItemType,
  DialerType,
  UpdateDialerDto,
} from '../../types/dialer/dialer.type';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setShowModal, setModalContent } from '../utils/utils.slice';

export const postDialers = createAsyncThunk<
  AsyncTaskType,
  {
    data: {
      warehouse: { id: number };
      equipment_group: { id: number };
      dialers: Array<
        Pick<
          DialerType,
          | 'radio_id'
          | 'description'
          | 'brand'
          | 'model'
          | 'cs_account'
          | 'cs_primary_receiver_country_code'
          | 'cs_primary_receiver'
          | 'cs_secondary_receiver_country_code'
          | 'cs_secondary_receiver'
          | 'config_key'
        > & { warehouse_location?: { rack: string; shelf: string }; is_inventory: boolean }
      >;
    };
  },
  { state: RootState }
>('dialers/postDialers', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<AsyncTaskType>(`${process.env.REACT_APP_API_URL}/dialer/bulk`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating dialers!');
  }
});

export const postDialerDetails = createAsyncThunk<DialerDetailsType, { data: CreateDialerDto }, { state: RootState }>(
  'dialers/postDialerDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<DialerDetailsType>(`${process.env.REACT_APP_API_URL}/dialer`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating dialer!');
    }
  }
);

export const getDialersList = createAsyncThunk<
  PaginationType<DialerListItemType>,
  RouteParamsType & { controller: AbortController },
  { state: RootState }
>('dialers/getDialersList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;

    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs,
      thunkAPI.getState().sharedReducer.selectedCountry
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<DialerListItemType>>(
      `${process.env.REACT_APP_API_URL}/dialer${queryString ? `?${queryString}` : ''}`,
      { signal: controller.signal }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching dialers!');
  }
});

export const getDialerDetails = createAsyncThunk<DialerDetailsType, { id: number }, { state: RootState }>(
  'dialers/getDialerDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<DialerDetailsType>(`${process.env.REACT_APP_API_URL}/dialer/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching dialer!');
    }
  }
);

export const patchDialerDetails = createAsyncThunk<
  DialerDetailsType,
  { id: number; data: UpdateDialerDto },
  { state: RootState }
>('dialers/patchDialerDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<DialerDetailsType>(`${process.env.REACT_APP_API_URL}/dialer/${id}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating dialer!');
  }
});

export const patchEnabledDialerDetails = createAsyncThunk<
  DialerDetailsType,
  { id: number; enabled: boolean },
  { state: RootState }
>('dialers/patchEnabledDialerDetails', async ({ enabled, id }, thunkAPI) => {
  try {
    const response = await axios.patch<DialerDetailsType>(
      `${process.env.REACT_APP_API_URL}/dialer/${id}/toggle-enabled`,
      { enabled }
    );
    thunkAPI.dispatch(getDialerDetails({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating dialer!');
  }
});

export const deleteDialerDetails = createAsyncThunk<
  DialerDetailsType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('dialers/deleteDialerDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<DialerDetailsType>(`${process.env.REACT_APP_API_URL}/dialer/${id}`);
    if (fetchList) thunkAPI.dispatch(getDialersList(thunkAPI.getState().dialersReducer.dialersRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting dialer!');
  }
});

import { createSlice } from '@reduxjs/toolkit';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { UserType } from '../../types/user/user.type';
import {
  deleteAgreementUserDetails,
  deleteAPICredentialsDetails,
  deleteUserAccessDetails,
  deleteUserDetails,
  deleteVerifiedEmailDetails,
  deleteVerifiedNumberDetails,
  getAPICredentialsDetails,
  getUserAccessList,
  getUserAuditLogsList,
  getUserDetails,
  getUserRoles,
  getUsersList,
  patchAPICredentialsDetails,
  patchUserDetails,
  patchUserPassword,
  patchUserPasswordAsSuperAdmin,
  postAgreementUserDetails,
  postAPICredentialsDetails,
  postResendVerifiedEmail,
  postResendVerifiedNumber,
  postSwitchUserOrganization,
  postUserAccess,
  postUserDetails,
  postVerifiedEmail,
  postVerifiedNumber,
  postVerifyVerifiedEmail,
  postVerifyVerifiedNumber,
} from './users.action';
import snackbarUtils from '../../utils/SnackbarUtils';
import { VerifiedNumberType } from '../../types/verified-number/verified-number.type';
import { VerifiedEmailType } from '../../types/verified-email/verified-email.type';

export interface UserState {
  usersList: null | PaginationType<UserType>;
  usersListFetchStatus: FETCH_STATUS;
  usersRouteParams: RouteParamsType;
  userDetails: null | UserType;
  userDetailsFetchStatus: FETCH_STATUS;
  userDetailsPostStatus: FETCH_STATUS;
  userDetailsPatchStatus: FETCH_STATUS;
  userDetailsDeleteStatus: FETCH_STATUS;
  userPasswordPatchStatus: FETCH_STATUS;
  userPasswordAsSuperAdminPatchStatus: FETCH_STATUS;
  userRoles: null | Array<{ id: string; name: string }>;
  userRolesFetchStatus: FETCH_STATUS;

  // USER AUDIT LOGS
  userAuditLogs: null | PaginationType<any>;
  userAuditLogsFetchStatus: FETCH_STATUS;
  userAuditLogsRouteParams: RouteParamsType;

  // USER AGREEMENT
  userAgreementDetailsPostStatus: FETCH_STATUS;
  userAgreementDetailsDeleteStatus: FETCH_STATUS;

  // API CREDENTIALS
  apiCredentials: null | unknown;
  apiCredentialsFetchStatus: FETCH_STATUS;
  apiCredentialsPostStatus: FETCH_STATUS;
  apiCredentialsPatchStatus: FETCH_STATUS;
  apiCredentialsDeleteStatus: FETCH_STATUS;

  // USER ACCESS
  userAccessList: null | Array<unknown>;
  userAccessListFetchStatus: FETCH_STATUS;
  userAccessPostStatus: FETCH_STATUS;
  userAccessDeleteStatus: FETCH_STATUS;
  switchUserOrganizationPostStatus: FETCH_STATUS;

  // VERIFICATION
  verifiedNumberPostStatus: FETCH_STATUS;
  verifiedNumberDetails: unknown | null;
  verifiedEmailPostStatus: FETCH_STATUS;
  verifiedEmailDetails: unknown | null;
  userIPFetchStatus: FETCH_STATUS;
  verifiedNumberDeleteStatus: FETCH_STATUS;
  verifiedEmailDeleteStatus: FETCH_STATUS;
  verifyVerifiedNumberPostStatus: FETCH_STATUS;
  verifyVerifiedEmailPostStatus: FETCH_STATUS;
  resendVerifiedNumberPostStatus: FETCH_STATUS;
  resendVerifiedEmailPostStatus: FETCH_STATUS;
}

export const initialUsersRouteParams = {
  page: 1,
  limit: 10,
  order: '-user.id',
  q: '',
  status: 'all',
  role: '',
};

export const initialUserAuditLogsRouteParams = {
  page: 1,
  limit: 10,
  order: '-audit_log.id',
  q: '',
};

const INIT_STATE: UserState = {
  usersList: null,
  usersListFetchStatus: FETCH_STATUS.NULL,
  usersRouteParams: initialUsersRouteParams,
  userDetails: null,
  userDetailsFetchStatus: FETCH_STATUS.NULL,
  userDetailsPostStatus: FETCH_STATUS.NULL,
  userDetailsPatchStatus: FETCH_STATUS.NULL,
  userPasswordPatchStatus: FETCH_STATUS.NULL,
  userPasswordAsSuperAdminPatchStatus: FETCH_STATUS.NULL,
  userDetailsDeleteStatus: FETCH_STATUS.NULL,
  userRoles: null,
  userRolesFetchStatus: FETCH_STATUS.NULL,

  // USER AUDIT LOGS
  userAuditLogs: null,
  userAuditLogsFetchStatus: FETCH_STATUS.NULL,
  userAuditLogsRouteParams: initialUserAuditLogsRouteParams,

  // USER AGREEMENT
  userAgreementDetailsPostStatus: FETCH_STATUS.NULL,
  userAgreementDetailsDeleteStatus: FETCH_STATUS.NULL,

  // API CREDENTIALS
  apiCredentials: null,
  apiCredentialsDeleteStatus: FETCH_STATUS.NULL,
  apiCredentialsFetchStatus: FETCH_STATUS.NULL,
  apiCredentialsPatchStatus: FETCH_STATUS.NULL,
  apiCredentialsPostStatus: FETCH_STATUS.NULL,

  // USER ACCESS
  userAccessList: null,
  userAccessListFetchStatus: FETCH_STATUS.NULL,
  userAccessPostStatus: FETCH_STATUS.NULL,
  userAccessDeleteStatus: FETCH_STATUS.NULL,
  switchUserOrganizationPostStatus: FETCH_STATUS.NULL,

  // VERIFICATION
  verifiedNumberPostStatus: FETCH_STATUS.NULL,
  verifiedNumberDetails: null,
  verifiedEmailPostStatus: FETCH_STATUS.NULL,
  verifiedEmailDetails: null,
  userIPFetchStatus: FETCH_STATUS.NULL,
  verifiedNumberDeleteStatus: FETCH_STATUS.NULL,
  verifiedEmailDeleteStatus: FETCH_STATUS.NULL,
  verifyVerifiedNumberPostStatus: FETCH_STATUS.NULL,
  verifyVerifiedEmailPostStatus: FETCH_STATUS.NULL,
  resendVerifiedNumberPostStatus: FETCH_STATUS.NULL,
  resendVerifiedEmailPostStatus: FETCH_STATUS.NULL,
};

export const usersSlice = createSlice({
  name: 'user',
  initialState: INIT_STATE,
  reducers: {
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    },
    setUsersListFetchStatus: (state, action) => {
      state.usersListFetchStatus = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setUserDetailsFetchStatus: (state, action) => {
      state.userDetailsFetchStatus = action.payload;
    },
    setUserDetailsPostStatus: (state, action) => {
      state.userDetailsPostStatus = action.payload;
    },
    setUserDetailsPatchStatus: (state, action) => {
      state.userDetailsPatchStatus = action.payload;
    },
    setUserDetailsDeleteStatus: (state, action) => {
      state.userDetailsDeleteStatus = action.payload;
    },
    setUserPasswordPatchStatus: (state, action) => {
      state.userPasswordPatchStatus = action.payload;
    },
    setUserPasswordAsSuperAdminPatchStatus: (state, action) => {
      state.userPasswordAsSuperAdminPatchStatus = action.payload;
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    setUserRolesFetchStatus: (state, action) => {
      state.userRolesFetchStatus = action.payload;
    },

    setUserAuditLogs: (state, action) => {
      state.userAuditLogs = action.payload;
    },
    setUserAuditLogsFetchStatus: (state, action) => {
      state.userAuditLogsFetchStatus = action.payload;
    },
    // USER AGREEMENT
    setUserAgreementDetailsDeleteStatus: (state, action) => {
      state.userAgreementDetailsDeleteStatus = action.payload;
    },
    setUserAgreementDetailsPostStatus: (state, action) => {
      state.userAgreementDetailsPostStatus = action.payload;
    },
    // API CREDENTIALS
    setAPICredentials: (state, action) => {
      state.apiCredentials = action.payload;
    },
    setAPICredentialsFetchStatus: (state, action) => {
      state.apiCredentialsFetchStatus = action.payload;
    },
    setAPICredentialsPostStatus: (state, action) => {
      state.apiCredentialsPostStatus = action.payload;
    },
    setAPICredentialsPatchStatus: (state, action) => {
      state.apiCredentialsPatchStatus = action.payload;
    },
    setAPICredentialsDeleteStatus: (state, action) => {
      state.apiCredentialsDeleteStatus = action.payload;
    },
    setUserAccessList: (state, action) => {
      state.userAccessList = action.payload;
    },
    setUserAccessListFetchStatus: (state, action) => {
      state.userAccessListFetchStatus = action.payload;
    },
    setUserAccessPostStatus: (state, action) => {
      state.userAccessPostStatus = action.payload;
    },
    setUserAccessDeleteStatus: (state, action) => {
      state.userAccessDeleteStatus = action.payload;
    },
    setSwitchUserOrganizationPostStatus: (state, action) => {
      state.switchUserOrganizationPostStatus = action.payload;
    },
    setVerifiedNumberPostStatus: (state, action) => {
      state.verifiedNumberPostStatus = action.payload;
    },
    setVerifiedNumberDetails: (state, action) => {
      state.verifiedNumberDetails = action.payload;
    },
    setVerifiedEmailPostStatus: (state, action) => {
      state.verifiedEmailPostStatus = action.payload;
    },
    setVerifiedEmailDetails: (state, action) => {
      state.verifiedEmailDetails = action.payload;
    },
    setUserIPFetchStatus: (state, action) => {
      state.userIPFetchStatus = action.payload;
    },
    setVerifiedNumberDeleteStatus: (state, action) => {
      state.verifiedNumberDeleteStatus = action.payload;
    },
    setVerifiedEmailDeleteStatus: (state, action) => {
      state.verifiedEmailDeleteStatus = action.payload;
    },
    setVerifyVerifiedEmailPostStatus: (state, action) => {
      state.verifyVerifiedEmailPostStatus = action.payload;
    },
    setVerifyVerifiedNumberPostStatus: (state, action) => {
      state.verifyVerifiedNumberPostStatus = action.payload;
    },
    setResendVerifiedEmailPostStatus: (state, action) => {
      state.resendVerifiedEmailPostStatus = action.payload;
    },
    setResendVerifiedNumberPostStatus: (state, action) => {
      state.resendVerifiedNumberPostStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'users') {
        state.usersRouteParams = { ...state.usersRouteParams, ...payload.data };
      }

      if (payload.reducer === 'users-audit-logs') {
        state.userAuditLogsRouteParams = { ...state.userAuditLogsRouteParams, ...payload.data };
      }
    });

    // CREATE USER DETAILS
    builder.addCase(postUserDetails.pending, state => {
      state.userDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postUserDetails.rejected, (state, action) => {
      state.userDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postUserDetails.fulfilled, state => {
      state.userDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('User created successfully!'));
    });

    // GET USER LIST
    builder.addCase(getUsersList.pending, state => {
      state.usersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getUsersList.rejected, (state, action) => {
      if (action.payload) {
        state.usersListFetchStatus = FETCH_STATUS.REJECTED;
        // state.usersList = null;
        setTimeout(() => snackbarUtils.error(action.payload as string));
      }
    });
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      state.usersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.usersList = action.payload;
    });

    // GET USER DETAILS
    builder.addCase(getUserDetails.pending, state => {
      state.userDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.userDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.userDetails = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.userDetails = action.payload;
    });

    // PATCH USER DETAILS
    builder.addCase(patchUserDetails.pending, state => {
      state.userDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchUserDetails.rejected, (state, action) => {
      state.userDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchUserDetails.fulfilled, state => {
      state.userDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('User updated successfully!'));
    });

    // DELETE USER DETAILS
    builder.addCase(deleteUserDetails.pending, state => {
      state.userDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteUserDetails.rejected, (state, action) => {
      state.userDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteUserDetails.fulfilled, (state, action) => {
      if (action.payload.fetchList) {
        state.userDetailsDeleteStatus = FETCH_STATUS.NULL;
      } else {
        state.userDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => snackbarUtils.success('User deleted successfully!'));
    });

    // CREATE USER AGREEMENTS DETAILS
    builder.addCase(postAgreementUserDetails.pending, state => {
      state.userDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postAgreementUserDetails.rejected, (state, action) => {
      state.userDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postAgreementUserDetails.fulfilled, state => {
      state.userDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Agreement accepted!'));
    });

    // DELETE USER AGREEMENTS DETAILS
    builder.addCase(deleteAgreementUserDetails.pending, state => {
      state.userDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteAgreementUserDetails.rejected, (state, action) => {
      state.userDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteAgreementUserDetails.fulfilled, state => {
      state.userDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
    });

    // PATCH PASSWORD AS SUPERADMIN
    builder.addCase(patchUserPasswordAsSuperAdmin.pending, state => {
      state.userPasswordAsSuperAdminPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchUserPasswordAsSuperAdmin.rejected, (state, action) => {
      state.userPasswordAsSuperAdminPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchUserPasswordAsSuperAdmin.fulfilled, state => {
      state.userPasswordAsSuperAdminPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Password has been changed!'));
    });

    // PATCH PASSWORD
    builder.addCase(patchUserPassword.pending, state => {
      state.userPasswordPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchUserPassword.rejected, (state, action) => {
      state.userPasswordPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchUserPassword.fulfilled, state => {
      state.userPasswordPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Password has been changed!'));
    });

    // GET USER ROLES
    builder.addCase(getUserRoles.pending, state => {
      state.userRolesFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getUserRoles.rejected, (state, action) => {
      state.userRolesFetchStatus = FETCH_STATUS.REJECTED;
      state.userRoles = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getUserRoles.fulfilled, (state, action) => {
      state.userRolesFetchStatus = FETCH_STATUS.FULFILLED;
      state.userRoles = action.payload;
    });

    // GET API CREDENTIALS
    builder.addCase(getAPICredentialsDetails.pending, state => {
      state.apiCredentialsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getAPICredentialsDetails.rejected, (state, action) => {
      state.apiCredentialsFetchStatus = FETCH_STATUS.REJECTED;
      state.apiCredentials = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getAPICredentialsDetails.fulfilled, (state, action) => {
      state.apiCredentialsFetchStatus = FETCH_STATUS.FULFILLED;
      state.apiCredentials = action.payload;
    });

    // POST API CREDENTIALS
    builder.addCase(postAPICredentialsDetails.pending, state => {
      state.apiCredentialsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postAPICredentialsDetails.rejected, (state, action) => {
      state.apiCredentialsPostStatus = FETCH_STATUS.REJECTED;
      state.apiCredentials = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postAPICredentialsDetails.fulfilled, (state, action) => {
      state.apiCredentialsPostStatus = FETCH_STATUS.FULFILLED;
      state.apiCredentials = action.payload;
    });

    // PATCH API CREDENTIALS
    builder.addCase(patchAPICredentialsDetails.pending, state => {
      state.apiCredentialsPatchStatus = FETCH_STATUS.PENDING;
      state.apiCredentials = null;
    });
    builder.addCase(patchAPICredentialsDetails.rejected, (state, action) => {
      state.apiCredentialsPatchStatus = FETCH_STATUS.REJECTED;
      state.apiCredentials = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchAPICredentialsDetails.fulfilled, (state, action) => {
      state.apiCredentialsPatchStatus = FETCH_STATUS.FULFILLED;
      state.apiCredentials = action.payload;
    });

    // DELETE API CREDENTIALS
    builder.addCase(deleteAPICredentialsDetails.pending, state => {
      state.apiCredentialsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteAPICredentialsDetails.rejected, (state, action) => {
      state.apiCredentialsDeleteStatus = FETCH_STATUS.REJECTED;
      state.apiCredentials = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteAPICredentialsDetails.fulfilled, (state, action) => {
      state.apiCredentialsDeleteStatus = FETCH_STATUS.FULFILLED;
      state.apiCredentials = null;
    });
    // GET USER ACCESS
    builder.addCase(getUserAccessList.pending, state => {
      state.userAccessListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getUserAccessList.rejected, (state, action) => {
      state.userAccessListFetchStatus = FETCH_STATUS.REJECTED;
      state.userAccessList = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getUserAccessList.fulfilled, (state, action) => {
      state.userAccessListFetchStatus = FETCH_STATUS.FULFILLED;
      state.userAccessList = action.payload;
    });
    // CREATE USER ACCESS
    builder.addCase(postUserAccess.pending, state => {
      state.userAccessPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postUserAccess.rejected, (state, action) => {
      state.userAccessPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postUserAccess.fulfilled, state => {
      state.userAccessPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Access added successfully!'));
    });
    // SWITCH USER ACCESS
    builder.addCase(postSwitchUserOrganization.pending, state => {
      state.switchUserOrganizationPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postSwitchUserOrganization.rejected, (state, action) => {
      state.switchUserOrganizationPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postSwitchUserOrganization.fulfilled, state => {
      state.switchUserOrganizationPostStatus = FETCH_STATUS.FULFILLED;
    });
    // DELETE USER ACCESS
    builder.addCase(deleteUserAccessDetails.pending, state => {
      state.userAccessDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteUserAccessDetails.rejected, (state, action) => {
      state.userAccessDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteUserAccessDetails.fulfilled, state => {
      state.userAccessDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Access removed successfully!'));
    });

    // POST VERIFICATION NUMBER
    builder.addCase(postVerifiedNumber.pending, state => {
      state.verifiedNumberPostStatus = FETCH_STATUS.PENDING;
      state.userDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postVerifiedNumber.rejected, (state, action) => {
      state.verifiedNumberPostStatus = FETCH_STATUS.REJECTED;
      state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postVerifiedNumber.fulfilled, (state, action) => {
      state.verifiedNumberPostStatus = FETCH_STATUS.FULFILLED;
      state.verifiedNumberDetails = action.payload;
      const user = { ...state.userDetails };
      if (user) {
        const verificationNumbers = [...(user.verified_numbers || []), action.payload];

        state.userDetails = {
          ...state.userDetails,
          verified_numbers: verificationNumbers as Array<VerifiedNumberType>,
        } as UserType;
        state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => snackbarUtils.success('Number has been added, check your phone for verification!'));
    });

    // POST VERIFICATION EMAIL
    builder.addCase(postVerifiedEmail.pending, state => {
      state.verifiedEmailPostStatus = FETCH_STATUS.PENDING;
      state.userDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postVerifiedEmail.rejected, (state, action) => {
      state.verifiedEmailPostStatus = FETCH_STATUS.REJECTED;
      state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postVerifiedEmail.fulfilled, (state, action) => {
      state.verifiedEmailPostStatus = FETCH_STATUS.FULFILLED;
      const user = { ...state.userDetails };
      if (user) {
        const verificationEmails = [...(user.verified_emails || []), action.payload];

        state.userDetails = {
          ...state.userDetails,
          verified_emails: verificationEmails as Array<VerifiedEmailType>,
        } as UserType;
        state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => snackbarUtils.success('Email has been added, check your email for verification!'));
    });

    // DELETE VERIFICATION EMAIL
    builder.addCase(deleteVerifiedNumberDetails.pending, state => {
      state.verifiedNumberDeleteStatus = FETCH_STATUS.PENDING;
      state.userDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteVerifiedNumberDetails.rejected, (state, action) => {
      state.verifiedNumberDeleteStatus = FETCH_STATUS.REJECTED;
      state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteVerifiedNumberDetails.fulfilled, (state, action) => {
      state.verifiedNumberDeleteStatus = FETCH_STATUS.FULFILLED;
      const user = { ...state.userDetails };
      if (user) {
        const verificationNumbers = [...(user.verified_numbers || [])].filter(vn => vn.id !== action.meta.arg.id);

        state.userDetails = {
          ...state.userDetails,
          verified_numbers: verificationNumbers as Array<VerifiedNumberType>,
        } as UserType;
        state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => snackbarUtils.success('Number has been removed!'));
    });

    // GET AUDIT LOGS LIST
    builder.addCase(getUserAuditLogsList.pending, state => {
      state.userAuditLogsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getUserAuditLogsList.rejected, (state, action) => {
      state.userAuditLogsFetchStatus = FETCH_STATUS.REJECTED;
      state.userAuditLogs = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getUserAuditLogsList.fulfilled, (state, action) => {
      state.userAuditLogsFetchStatus = FETCH_STATUS.FULFILLED;
      state.userAuditLogs = action.payload;
    });

    // DELETE VERIFICATION EMAIL
    builder.addCase(deleteVerifiedEmailDetails.pending, state => {
      state.verifiedEmailDeleteStatus = FETCH_STATUS.PENDING;
      state.userDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteVerifiedEmailDetails.rejected, (state, action) => {
      state.verifiedEmailDeleteStatus = FETCH_STATUS.REJECTED;
      state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteVerifiedEmailDetails.fulfilled, (state, action) => {
      state.verifiedEmailDeleteStatus = FETCH_STATUS.FULFILLED;
      const user = { ...state.userDetails };
      if (user) {
        const verificationEmails = [...(user.verified_emails || [])].filter(ve => ve.id !== action.meta.arg.id);

        state.userDetails = {
          ...state.userDetails,
          verified_emails: verificationEmails as Array<VerifiedEmailType>,
        } as UserType;
        state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => snackbarUtils.success('Email has been removed!'));
    });

    // VERIFY VERIFICATION NUMBER
    builder.addCase(postVerifyVerifiedNumber.pending, state => {
      state.verifyVerifiedNumberPostStatus = FETCH_STATUS.PENDING;
      state.userDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postVerifyVerifiedNumber.rejected, (state, action) => {
      state.verifyVerifiedNumberPostStatus = FETCH_STATUS.REJECTED;
      state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postVerifyVerifiedNumber.fulfilled, (state, action) => {
      state.verifyVerifiedNumberPostStatus = FETCH_STATUS.FULFILLED;
      const user = { ...state.userDetails };
      if (user) {
        const verificationNumbers = [...(user.verified_numbers || [])].map(vn => {
          if (vn.id === action.meta.arg.data.id) {
            return { ...vn, is_number_verified: true };
          }

          return { ...vn };
        });

        state.userDetails = {
          ...state.userDetails,
          verified_numbers: verificationNumbers as Array<VerifiedNumberType>,
        } as UserType;
        state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => snackbarUtils.success('The number has been verified!'));
    });

    // VERIFY VERIFICATION EMAIL
    builder.addCase(postVerifyVerifiedEmail.pending, state => {
      state.verifyVerifiedEmailPostStatus = FETCH_STATUS.PENDING;
      state.userDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postVerifyVerifiedEmail.rejected, (state, action) => {
      state.verifyVerifiedEmailPostStatus = FETCH_STATUS.REJECTED;
      state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postVerifyVerifiedEmail.fulfilled, state => {
      state.verifyVerifiedEmailPostStatus = FETCH_STATUS.FULFILLED;
      const user = { ...state.userDetails };
      if (user) {
        state.userDetails = { ...state.userDetails } as UserType;
        state.userDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      }
      setTimeout(() => snackbarUtils.success('The email has been verified!'));
    });

    // RESEND VERIFICATION EMAIL
    builder.addCase(postResendVerifiedEmail.pending, state => {
      state.resendVerifiedEmailPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postResendVerifiedEmail.rejected, (state, action) => {
      state.resendVerifiedEmailPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postResendVerifiedEmail.fulfilled, state => {
      state.resendVerifiedEmailPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Check your email for verification!'));
    });

    // RESEND VERIFICATION NUMBER
    builder.addCase(postResendVerifiedNumber.pending, state => {
      state.resendVerifiedNumberPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postResendVerifiedNumber.rejected, (state, action) => {
      state.resendVerifiedNumberPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postResendVerifiedNumber.fulfilled, state => {
      state.resendVerifiedNumberPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Check your phone for verification!'));
    });
  },
});

export const {
  setUserDetailsDeleteStatus,
  setUserDetailsPatchStatus,
  setUserDetailsPostStatus,
  setUsersListFetchStatus,
  setUserDetailsFetchStatus,
  setUsersList,
  setUserDetails,
  setUserAgreementDetailsPostStatus,
  setUserAgreementDetailsDeleteStatus,
  setUserPasswordPatchStatus,
  setUserPasswordAsSuperAdminPatchStatus,
  setUserRoles,
  setUserRolesFetchStatus,
  setAPICredentialsDeleteStatus,
  setAPICredentialsFetchStatus,
  setAPICredentialsPatchStatus,
  setAPICredentialsPostStatus,
  setAPICredentials,
  setUserAccessDeleteStatus,
  setUserAccessListFetchStatus,
  setUserAccessPostStatus,
  setSwitchUserOrganizationPostStatus,
  setVerifiedNumberDetails,
  setUserAccessList,
  setVerifiedNumberPostStatus,
  setVerifiedEmailPostStatus,
  setVerifiedEmailDetails,
  setUserIPFetchStatus,
  setVerifiedNumberDeleteStatus,
  setVerifiedEmailDeleteStatus,
  setVerifyVerifiedNumberPostStatus,
  setVerifyVerifiedEmailPostStatus,
  setResendVerifiedNumberPostStatus,
  setResendVerifiedEmailPostStatus,
  setUserAuditLogsFetchStatus,
  setUserAuditLogs,
} = usersSlice.actions;

export default usersSlice.reducer;

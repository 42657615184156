import { createSlice } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { WarehouseDetailsType, WarehouseListItemType } from '../../types/warehouse/warehouse.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import {
  deleteMicroserviceWarehouseDetails,
  deleteWarehouseDetails,
  getMicroserviceWarehouseDetails,
  getMicroserviceWarehousesList,
  getWarehouseDetails,
  getWarehousesList,
  patchMicroserviceWarehouseDetails,
  patchWarehouseDetails,
  postMicroserviceWarehouseDetails,
  postWarehouseDetails,
} from './warehouses.action';
import snackbarUtils from '../../utils/SnackbarUtils';

export interface WarehousesState {
  warehousesList: null | PaginationType<WarehouseListItemType>;
  warehousesListFetchStatus: FETCH_STATUS;
  warehousesRouteParams: RouteParamsType;
  warehouseDetails: null | WarehouseDetailsType;
  warehouseDetailsFetchStatus: FETCH_STATUS;
  warehouseDetailsPostStatus: FETCH_STATUS;
  warehouseDetailsPatchStatus: FETCH_STATUS;
  warehouseDetailsDeleteStatus: FETCH_STATUS;

  microserviceWarehousesList: null | PaginationType<WarehouseListItemType>;
  microserviceWarehousesListFetchStatus: FETCH_STATUS;
  microserviceWarehousesRouteParams: RouteParamsType;
  microserviceWarehouseDetails: null | WarehouseDetailsType;
  microserviceWarehouseDetailsFetchStatus: FETCH_STATUS;
  microserviceWarehouseDetailsPostStatus: FETCH_STATUS;
  microserviceWarehouseDetailsPatchStatus: FETCH_STATUS;
  microserviceWarehouseDetailsDeleteStatus: FETCH_STATUS;
}

export const initialWarehousesRouteParams = {
  page: 1,
  limit: 10,
  order: '-warehouse.id',
  q: '',
  country_id: '',
  country_category: '',
};

const INIT_STATE: WarehousesState = {
  warehousesRouteParams: initialWarehousesRouteParams,
  warehousesList: null,
  warehousesListFetchStatus: FETCH_STATUS.NULL,
  warehouseDetails: null,
  warehouseDetailsFetchStatus: FETCH_STATUS.NULL,
  warehouseDetailsPostStatus: FETCH_STATUS.NULL,
  warehouseDetailsPatchStatus: FETCH_STATUS.NULL,
  warehouseDetailsDeleteStatus: FETCH_STATUS.NULL,

  microserviceWarehousesRouteParams: initialWarehousesRouteParams,
  microserviceWarehousesList: null,
  microserviceWarehousesListFetchStatus: FETCH_STATUS.NULL,
  microserviceWarehouseDetails: null,
  microserviceWarehouseDetailsFetchStatus: FETCH_STATUS.NULL,
  microserviceWarehouseDetailsPostStatus: FETCH_STATUS.NULL,
  microserviceWarehouseDetailsPatchStatus: FETCH_STATUS.NULL,
  microserviceWarehouseDetailsDeleteStatus: FETCH_STATUS.NULL,
};

export const warehousesSlice = createSlice({
  name: 'warehouses',
  initialState: INIT_STATE,
  reducers: {
    setWarehousesList: (state, action) => {
      state.warehousesList = action.payload;
    },
    setWarehousesListFetchStatus: (state, action) => {
      state.warehousesListFetchStatus = action.payload;
    },
    setWarehouseDetails: (state, action) => {
      state.warehouseDetails = action.payload;
    },
    setWarehouseDetailsFetchStatus: (state, action) => {
      state.warehouseDetailsFetchStatus = action.payload;
    },
    setWarehouseDetailsPostStatus: (state, action) => {
      state.warehouseDetailsPostStatus = action.payload;
    },
    setWarehouseDetailsPatchStatus: (state, action) => {
      state.warehouseDetailsPatchStatus = action.payload;
    },
    setWarehouseDetailsDeleteStatus: (state, action) => {
      state.warehouseDetailsDeleteStatus = action.payload;
    },

    setMicroserviceWarehousesList: (state, action) => {
      state.microserviceWarehousesList = action.payload;
    },
    setMicroserviceWarehousesListFetchStatus: (state, action) => {
      state.microserviceWarehousesListFetchStatus = action.payload;
    },
    setMicroserviceWarehouseDetails: (state, action) => {
      state.microserviceWarehouseDetails = action.payload;
    },
    setMicroserviceWarehouseDetailsFetchStatus: (state, action) => {
      state.microserviceWarehouseDetailsFetchStatus = action.payload;
    },
    setMicroserviceWarehouseDetailsPostStatus: (state, action) => {
      state.microserviceWarehouseDetailsPostStatus = action.payload;
    },
    setMicroserviceWarehouseDetailsPatchStatus: (state, action) => {
      state.microserviceWarehouseDetailsPatchStatus = action.payload;
    },
    setMicroserviceWarehouseDetailsDeleteStatus: (state, action) => {
      state.microserviceWarehouseDetailsDeleteStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'warehouses') {
        state.warehousesRouteParams = { ...state.warehousesRouteParams, ...payload.data };
      }

      if (payload.reducer === 'microservice-warehouses') {
        state.microserviceWarehousesRouteParams = { ...state.microserviceWarehousesRouteParams, ...payload.data };
      }
    });

    // CREATE WAREHOUSES DETAILS
    builder.addCase(postWarehouseDetails.pending, state => {
      state.warehouseDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postWarehouseDetails.rejected, (state, action) => {
      state.warehouseDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postWarehouseDetails.fulfilled, state => {
      state.warehouseDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Warehouse created successfully');
      });
    });

    // GET WAREHOUSES LIST
    builder.addCase(getWarehousesList.pending, state => {
      state.warehousesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getWarehousesList.rejected, (state, action) => {
      if (action.payload) {
        state.warehousesListFetchStatus = FETCH_STATUS.REJECTED;
        // state.warehousesList = null;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getWarehousesList.fulfilled, (state, action) => {
      state.warehousesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.warehousesList = action.payload;
    });

    // GET WAREHOUSES DETAILS
    builder.addCase(getWarehouseDetails.pending, state => {
      state.warehouseDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getWarehouseDetails.rejected, (state, action) => {
      state.warehouseDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.warehouseDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getWarehouseDetails.fulfilled, (state, action) => {
      state.warehouseDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.warehouseDetails = action.payload;
    });

    // PATCH WAREHOUSES DETAILS
    builder.addCase(patchWarehouseDetails.pending, state => {
      state.warehouseDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchWarehouseDetails.rejected, (state, action) => {
      state.warehouseDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchWarehouseDetails.fulfilled, state => {
      state.warehouseDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Warehouse updated successfully');
      });
    });

    // DELETE WAREHOUSES DETAILS
    builder.addCase(deleteWarehouseDetails.pending, state => {
      state.warehouseDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteWarehouseDetails.rejected, (state, action) => {
      state.warehouseDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteWarehouseDetails.fulfilled, state => {
      state.warehouseDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Warehouse deleted successfully');
      });
    });

    // CREATE WAREHOUSES DETAILS
    builder.addCase(postMicroserviceWarehouseDetails.pending, state => {
      state.microserviceWarehouseDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postMicroserviceWarehouseDetails.rejected, (state, action) => {
      state.microserviceWarehouseDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postMicroserviceWarehouseDetails.fulfilled, state => {
      state.microserviceWarehouseDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Warehouse created successfully');
      });
    });

    // GET WAREHOUSES LIST
    builder.addCase(getMicroserviceWarehousesList.pending, state => {
      state.microserviceWarehousesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getMicroserviceWarehousesList.rejected, (state, action) => {
      if (action.payload) {
        state.microserviceWarehousesListFetchStatus = FETCH_STATUS.REJECTED;
        // state.microserviceWarehousesList = null;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getMicroserviceWarehousesList.fulfilled, (state, action) => {
      state.microserviceWarehousesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.microserviceWarehousesList = action.payload;
    });

    // GET WAREHOUSES DETAILS
    builder.addCase(getMicroserviceWarehouseDetails.pending, state => {
      state.microserviceWarehouseDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getMicroserviceWarehouseDetails.rejected, (state, action) => {
      state.microserviceWarehouseDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.microserviceWarehouseDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getMicroserviceWarehouseDetails.fulfilled, (state, action) => {
      state.microserviceWarehouseDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.microserviceWarehouseDetails = action.payload;
    });

    // PATCH WAREHOUSES DETAILS
    builder.addCase(patchMicroserviceWarehouseDetails.pending, state => {
      state.microserviceWarehouseDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchMicroserviceWarehouseDetails.rejected, (state, action) => {
      state.microserviceWarehouseDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchMicroserviceWarehouseDetails.fulfilled, state => {
      state.microserviceWarehouseDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Warehouse updated successfully');
      });
    });

    // DELETE WAREHOUSES DETAILS
    builder.addCase(deleteMicroserviceWarehouseDetails.pending, state => {
      state.microserviceWarehouseDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteMicroserviceWarehouseDetails.rejected, (state, action) => {
      state.microserviceWarehouseDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteMicroserviceWarehouseDetails.fulfilled, state => {
      state.microserviceWarehouseDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Warehouse deleted successfully');
      });
    });
  },
});

export const {
  setWarehousesList,
  setWarehousesListFetchStatus,
  setWarehouseDetailsFetchStatus,
  setWarehouseDetailsDeleteStatus,
  setWarehouseDetailsPatchStatus,
  setWarehouseDetailsPostStatus,
  setWarehouseDetails,
  setMicroserviceWarehousesList,
  setMicroserviceWarehouseDetailsDeleteStatus,
  setMicroserviceWarehouseDetailsFetchStatus,
  setMicroserviceWarehouseDetailsPatchStatus,
  setMicroserviceWarehouseDetailsPostStatus,
  setMicroserviceWarehousesListFetchStatus,
  setMicroserviceWarehouseDetails,
} = warehousesSlice.actions;

export default warehousesSlice.reducer;

import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import {
  RevioFieldIdDetailsType,
  RevioFieldIdListItemType,
} from '../../types/tc-service/tc-service-type/revio-field-id/revio-field-id.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteRevIoFieldIdDetails,
  deleteTcServiceTypeDetails,
  getRevIoFieldIdDetails,
  getRevIoFieldIdsList,
  getServiceTypeOverrides,
  getTcServiceDetails,
  getTcServicesList,
  getTcServiceTypeDetails,
  getTcServiceTypesList,
  patchAllServiceTypeOverride,
  patchRevIoFieldIdDetails,
  patchTcServiceTypeDetails,
  postCheckRevIoTcService,
  postFixRevIoService,
  postRevIoFieldIdDetails,
  postTcServiceTypeDetails,
} from './tcServices.action';
import { TcServiceType } from '../../types/tc-service/tc-service.type';
import { TcServiceTypeType } from '../../types/tc-service/tc-service-type/tc-service-type.type';
import { TcServiceTypeOverrideType } from '../../types/tc-service/tc-service-type/tc-service-type-override/tc-service-type-override.type';

export interface TcServiceTypeState {
  tcServicesList: null | PaginationType<TcServiceType>;
  tcServicesListFetchStatus: FETCH_STATUS;
  tcServicesRouteParams: RouteParamsType;
  tcServiceDetails: null | TcServiceType;
  tcServiceDetailsFetchStatus: FETCH_STATUS;

  tcServiceTypesRouteParams: RouteParamsType;
  tcServiceTypesList: null | PaginationType<TcServiceTypeType>;
  tcServiceTypesListFetchStatus: FETCH_STATUS;
  tcServiceTypeDetails: null | TcServiceTypeType;
  tcServiceTypeDetailsFetchStatus: FETCH_STATUS;
  tcServiceTypeDetailsPostStatus: FETCH_STATUS;
  tcServiceTypeDetailsPatchStatus: FETCH_STATUS;
  tcServiceTypeDetailsDeleteStatus: FETCH_STATUS;
  tcServiceTypeOverridesList: null | PaginationType<TcServiceTypeOverrideType>;
  tcServiceTypeOverridesListFetchStatus: FETCH_STATUS;
  tcServiceTypeOverridesListPatchStatus: FETCH_STATUS;

  revIoFieldIdsList: null | PaginationType<RevioFieldIdListItemType>;
  revIoFieldIdsListFetchStatus: FETCH_STATUS;
  revIoFieldIdsRouteParams: RouteParamsType;
  revIoFieldIdDetails: null | RevioFieldIdDetailsType;
  revIoFieldIdDetailsFetchStatus: FETCH_STATUS;
  revIoFieldIdDetailsPostStatus: FETCH_STATUS;
  revIoFieldIdDetailsPatchStatus: FETCH_STATUS;
  revIoFieldIdDetailsDeleteStatus: FETCH_STATUS;

  fixRevIoServicePostStatus: FETCH_STATUS;
  checkRevIoTcService: null | Array<string>;
  checkRevIoTcServicePostStatus: FETCH_STATUS;
}

export const initialrevIoFieldIdsRouteParams = {
  page: 1,
  limit: 10,
  order: '-revio_field_id.id',
  q: '',
  isBobo: 'all',
  service_type_id: '',
};

export const initialServicesRouteParams = {
  page: 1,
  limit: 10,
  order: '-tc_service.id',
  service_type_id: '',
  is_active: true,
  q: '',
  category: '',
};

export const initialServiceTypesRouteParams = {
  page: 1,
  limit: 10,
  order: '-tc_service_type.id',
  q: '',
  category: '',
};

const INIT_STATE: TcServiceTypeState = {
  tcServiceDetailsFetchStatus: FETCH_STATUS.NULL,
  tcServicesListFetchStatus: FETCH_STATUS.NULL,
  tcServicesList: null,
  tcServiceDetails: null,
  tcServicesRouteParams: initialServicesRouteParams,

  tcServiceTypesRouteParams: initialServiceTypesRouteParams,
  tcServiceTypesList: null,
  tcServiceTypesListFetchStatus: FETCH_STATUS.NULL,
  tcServiceTypeDetails: null,
  tcServiceTypeDetailsFetchStatus: FETCH_STATUS.NULL,
  tcServiceTypeDetailsPostStatus: FETCH_STATUS.NULL,
  tcServiceTypeDetailsPatchStatus: FETCH_STATUS.NULL,
  tcServiceTypeDetailsDeleteStatus: FETCH_STATUS.NULL,

  revIoFieldIdDetails: null,
  revIoFieldIdDetailsFetchStatus: FETCH_STATUS.NULL,
  revIoFieldIdDetailsDeleteStatus: FETCH_STATUS.NULL,
  revIoFieldIdDetailsPatchStatus: FETCH_STATUS.NULL,
  revIoFieldIdDetailsPostStatus: FETCH_STATUS.NULL,

  revIoFieldIdsListFetchStatus: FETCH_STATUS.NULL,
  revIoFieldIdsList: null,
  revIoFieldIdsRouteParams: initialrevIoFieldIdsRouteParams,

  fixRevIoServicePostStatus: FETCH_STATUS.NULL,
  checkRevIoTcService: null,
  checkRevIoTcServicePostStatus: FETCH_STATUS.NULL,
  tcServiceTypeOverridesList: null,
  tcServiceTypeOverridesListFetchStatus: FETCH_STATUS.NULL,
  tcServiceTypeOverridesListPatchStatus: FETCH_STATUS.NULL,
};

export const tcServicesSlice = createSlice({
  name: 'tcServiceType',
  initialState: INIT_STATE,
  reducers: {
    setRevIoFieldIdsList: (state, action) => {
      state.revIoFieldIdsList = action.payload;
    },
    setRevIoFieldIdsListFetchStatus: (state, action) => {
      state.revIoFieldIdsListFetchStatus = action.payload;
    },
    setRevIoFieldIdDetails: (state, action) => {
      state.revIoFieldIdDetails = action.payload;
    },
    setRevIoFieldIdDetailsFetchStatus: (state, action) => {
      state.revIoFieldIdDetailsFetchStatus = action.payload;
    },
    setRevIoFieldIdDetailsPostStatus: (state, action) => {
      state.revIoFieldIdDetailsPostStatus = action.payload;
    },
    setRevIoFieldIdDetailsPatchStatus: (state, action) => {
      state.revIoFieldIdDetailsPatchStatus = action.payload;
    },
    setRevIoFieldIdDetailsDeleteStatus: (state, action) => {
      state.revIoFieldIdDetailsDeleteStatus = action.payload;
    },
    setTcServicesList: (state, action) => {
      state.tcServicesList = action.payload;
    },
    setTcServicesListFetchStatus: (state, action) => {
      state.tcServicesListFetchStatus = action.payload;
    },
    setTcServiceDetails: (state, action) => {
      state.tcServiceDetails = action.payload;
    },
    setTcServiceDetailsFetchStatus: (state, action) => {
      state.tcServiceDetailsFetchStatus = action.payload;
    },
    setCheckRevIoTcService: (state, action) => {
      state.checkRevIoTcService = action.payload;
    },
    setCheckRevIoTcServicePostStatus: (state, action) => {
      state.checkRevIoTcServicePostStatus = action.payload;
    },
    setFixRevIoServicePostStatus: (state, action) => {
      state.fixRevIoServicePostStatus = action.payload;
    },
    setTcServiceTypesList: (state, action) => {
      state.tcServiceTypesList = action.payload;
    },
    setTcServiceTypesListFetchStatus: (state, action) => {
      state.tcServiceTypesListFetchStatus = action.payload;
    },
    setTcServiceTypeDetails: (state, action) => {
      state.tcServiceTypeDetails = action.payload;
    },
    setTcServiceTypeDetailsFetchStatus: (state, action) => {
      state.tcServiceTypeDetailsFetchStatus = action.payload;
    },
    setTcServiceTypeDetailsPostStatus: (state, action) => {
      state.tcServiceTypeDetailsPostStatus = action.payload;
    },
    setTcServiceTypeDetailsPatchStatus: (state, action) => {
      state.tcServiceTypeDetailsPatchStatus = action.payload;
    },
    setTcServiceTypeDetailsDeleteStatus: (state, action) => {
      state.tcServiceTypeDetailsDeleteStatus = action.payload;
    },
    setTcServiceTypeOverridesList: (state, action) => {
      state.tcServiceTypeOverridesList = action.payload;
    },
    setTcServiceTypeOverridesListFetchStatus: (state, action) => {
      state.tcServiceTypeOverridesListFetchStatus = action.payload;
    },
    setTcServiceTypeOverridesListPatchStatus: (state, action) => {
      state.tcServiceTypeOverridesListPatchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'revIoFiledIds') {
        state.revIoFieldIdsRouteParams = { ...state.revIoFieldIdsRouteParams, ...payload.data };
      }

      if (payload.reducer === 'services') {
        state.tcServicesRouteParams = { ...state.tcServicesRouteParams, ...payload.data };
      }

      if (payload.reducer === 'serviceTypes') {
        state.tcServiceTypesRouteParams = { ...state.tcServiceTypesRouteParams, ...payload.data };
      }
    });

    // CREATE REV.IO FIELD ID DETAILS
    builder.addCase(postRevIoFieldIdDetails.pending, state => {
      state.revIoFieldIdDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postRevIoFieldIdDetails.rejected, (state, action) => {
      state.revIoFieldIdDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postRevIoFieldIdDetails.fulfilled, state => {
      state.revIoFieldIdDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Rev.io field id created successfully');
      });
    });

    // GET REV.IO FIELD ID LIST
    builder.addCase(getRevIoFieldIdsList.pending, state => {
      state.revIoFieldIdsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRevIoFieldIdsList.rejected, (state, action) => {
      state.revIoFieldIdsListFetchStatus = FETCH_STATUS.REJECTED;
      state.revIoFieldIdsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getRevIoFieldIdsList.fulfilled, (state, action) => {
      state.revIoFieldIdsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.revIoFieldIdsList = action.payload;
    });

    // GET REV.IO FIELD ID DETAILS
    builder.addCase(getRevIoFieldIdDetails.pending, state => {
      state.revIoFieldIdDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRevIoFieldIdDetails.rejected, (state, action) => {
      state.revIoFieldIdDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.revIoFieldIdDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getRevIoFieldIdDetails.fulfilled, (state, action) => {
      state.revIoFieldIdDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.revIoFieldIdDetails = action.payload;
    });

    // PATCH REV.IO FIELD ID DETAILS
    builder.addCase(patchRevIoFieldIdDetails.pending, state => {
      state.revIoFieldIdDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchRevIoFieldIdDetails.rejected, (state, action) => {
      state.revIoFieldIdDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchRevIoFieldIdDetails.fulfilled, state => {
      state.revIoFieldIdDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Rev.io field id updated successfully');
      });
    });

    // DELETE REV.IO FIELD ID DETAILS
    builder.addCase(deleteRevIoFieldIdDetails.pending, state => {
      state.revIoFieldIdDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteRevIoFieldIdDetails.rejected, (state, action) => {
      state.revIoFieldIdDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteRevIoFieldIdDetails.fulfilled, state => {
      state.revIoFieldIdDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Rev.io field id deleted successfully');
      });
    });

    // GET TC-SERVICE LIST
    builder.addCase(getTcServicesList.pending, state => {
      state.tcServicesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getTcServicesList.rejected, (state, action) => {
      if (action.payload) {
        state.tcServicesListFetchStatus = FETCH_STATUS.REJECTED;
        // state.tcServicesList = null;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getTcServicesList.fulfilled, (state, action) => {
      state.tcServicesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.tcServicesList = action.payload;
    });

    // GET TC-SERVICE DETAILS
    builder.addCase(getTcServiceDetails.pending, state => {
      state.tcServiceDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getTcServiceDetails.rejected, (state, action) => {
      state.tcServiceDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.tcServiceDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getTcServiceDetails.fulfilled, (state, action) => {
      state.tcServiceDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.tcServiceDetails = action.payload;
    });

    // GET TC-SERVICE DETAILS
    builder.addCase(postCheckRevIoTcService.pending, state => {
      state.checkRevIoTcServicePostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postCheckRevIoTcService.rejected, (state, action) => {
      state.checkRevIoTcServicePostStatus = FETCH_STATUS.REJECTED;
      state.checkRevIoTcService = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postCheckRevIoTcService.fulfilled, (state, action) => {
      state.checkRevIoTcServicePostStatus = FETCH_STATUS.FULFILLED;
      state.checkRevIoTcService = action.payload;
    });

    // GET TC-SERVICE DETAILS
    builder.addCase(postFixRevIoService.pending, state => {
      state.fixRevIoServicePostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postFixRevIoService.rejected, (state, action) => {
      state.fixRevIoServicePostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postFixRevIoService.fulfilled, (state, action) => {
      state.fixRevIoServicePostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Rev.io service has been fixed! ' + action.payload);
      });
    });

    // CREATE TC SERVICE TYPES DETAILS
    builder.addCase(postTcServiceTypeDetails.pending, state => {
      state.tcServiceTypeDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postTcServiceTypeDetails.rejected, (state, action) => {
      state.tcServiceTypeDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postTcServiceTypeDetails.fulfilled, state => {
      state.tcServiceTypeDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Tc service type has been opened!');
      });
    });

    // GET TC SERVICE TYPES LIST
    builder.addCase(getTcServiceTypesList.pending, state => {
      state.tcServiceTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getTcServiceTypesList.rejected, (state, action) => {
      if (action.payload) {
        state.tcServiceTypesListFetchStatus = FETCH_STATUS.REJECTED;
        // state.tcServiceTypesList = null;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getTcServiceTypesList.fulfilled, (state, action) => {
      state.tcServiceTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.tcServiceTypesList = action.payload;
    });

    // GET TC SERVICE TYPES DETAILS
    builder.addCase(getTcServiceTypeDetails.pending, state => {
      state.tcServiceTypeDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getTcServiceTypeDetails.rejected, (state, action) => {
      state.tcServiceTypeDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.tcServiceTypeDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getTcServiceTypeDetails.fulfilled, (state, action) => {
      state.tcServiceTypeDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.tcServiceTypeDetails = action.payload;
    });

    // PATCH TC SERVICE TYPES DETAILS
    builder.addCase(patchTcServiceTypeDetails.pending, state => {
      state.tcServiceTypeDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchTcServiceTypeDetails.rejected, (state, action) => {
      state.tcServiceTypeDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchTcServiceTypeDetails.fulfilled, state => {
      state.tcServiceTypeDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Tc service types updated successfully');
      });
    });

    // DELETE TC SERVICE TYPES DETAILS
    builder.addCase(deleteTcServiceTypeDetails.pending, state => {
      state.tcServiceTypeDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteTcServiceTypeDetails.rejected, (state, action) => {
      state.tcServiceTypeDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteTcServiceTypeDetails.fulfilled, state => {
      state.tcServiceTypeDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Tc service type deleted successfully');
      });
    });

    // PATCH TC SERVICE TYPES DETAILS
    builder.addCase(patchAllServiceTypeOverride.pending, state => {
      state.tcServiceTypeOverridesListPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchAllServiceTypeOverride.rejected, (state, action) => {
      state.tcServiceTypeOverridesListPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchAllServiceTypeOverride.fulfilled, state => {
      state.tcServiceTypeOverridesListPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Service Type Overrides has been saved!');
      });
    });

    // GET TC SERVICE TYPES LIST
    builder.addCase(getServiceTypeOverrides.pending, state => {
      state.tcServiceTypeOverridesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getServiceTypeOverrides.rejected, (state, action) => {
      state.tcServiceTypeOverridesListFetchStatus = FETCH_STATUS.REJECTED;
      state.tcServiceTypeOverridesList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getServiceTypeOverrides.fulfilled, (state, action) => {
      state.tcServiceTypeOverridesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.tcServiceTypeOverridesList = action.payload;
    });
  },
});

export const {
  setRevIoFieldIdsList,
  setRevIoFieldIdsListFetchStatus,
  setRevIoFieldIdDetails,
  setRevIoFieldIdDetailsFetchStatus,
  setRevIoFieldIdDetailsPostStatus,
  setRevIoFieldIdDetailsPatchStatus,
  setRevIoFieldIdDetailsDeleteStatus,
  setTcServicesList,
  setTcServiceDetailsFetchStatus,
  setTcServicesListFetchStatus,
  setTcServiceDetails,
  setCheckRevIoTcServicePostStatus,
  setFixRevIoServicePostStatus,
  setCheckRevIoTcService,
  setTcServiceTypesList,
  setTcServiceTypeDetailsDeleteStatus,
  setTcServiceTypeDetailsFetchStatus,
  setTcServiceTypeDetailsPatchStatus,
  setTcServiceTypeDetailsPostStatus,
  setTcServiceTypesListFetchStatus,
  setTcServiceTypeDetails,
  setTcServiceTypeOverridesListFetchStatus,
  setTcServiceTypeOverridesList,
  setTcServiceTypeOverridesListPatchStatus,
} = tcServicesSlice.actions;

export default tcServicesSlice.reducer;

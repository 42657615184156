import { PermissionArrayType } from '../../../types/other';
import * as yup from 'yup';
import { CreateServiceCallAreaDto } from '../../../types/service-call-area/service-call-area.type';
import { CreateAddressDto } from '../../../types/address/address.type';
import { PERMISSIONROLECONSTANTS } from '../../../features/utils/utils.action';
import { ContactType } from '../../../types/contacts/contacts.type';
import { ServiceCallAttachmentType } from '../../../types/service-call-attachment/service-call-attachment.type';

export const serviceCallProviderPermissions: PermissionArrayType = {
  CREATE: [PERMISSIONROLECONSTANTS.superadmin],
  READ: [PERMISSIONROLECONSTANTS.superadmin],
  UPDATE: [PERMISSIONROLECONSTANTS.superadmin],
  DELETE: [PERMISSIONROLECONSTANTS.superadmin],
};

export const serviceCallProviderValidationSchema = yup.object({
  name: yup.string().required('Required'),
  website: yup.string().matches(RegExp('^$|(https?|chrome):\\/\\/[^\\s$.?#].[^\\s]*$'), 'Invalid URL').nullable(true),
  isActive: yup.boolean(),
  address: yup
    .object({
      zip_code: yup.string().required('Required'),
    })
    .nullable(true),
});

export interface ServiceCallProviderForm {
  id: number | null;
  name: string;
  website: string | null;
  isActive: boolean;
  areas: Array<CreateServiceCallAreaDto>;
  address: CreateAddressDto | null;
  note?: string | null;
  attachments: Array<ServiceCallAttachmentType>;
  files: Array<File>;
  contacts: Array<ContactType>;
}

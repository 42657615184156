import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import {
  CreateServiceCallTechnicianDto,
  ServiceCallTechnicianType,
  UpdateServiceCallTechnicianDto,
} from '../../types/service-call-technician/service-call-technician.type';
import {
  CreateServiceCallProviderDto,
  ServiceCallProviderType,
  UpdateServiceCallProviderDto,
} from '../../types/service-call-provider/service-call-provider.type';
import { ServiceCallNoteType } from '../../types/service-call-note/service-call-note.type';
import {
  CreateServiceCallRequestDto,
  ServiceCallRequestType,
  UpdateServiceCallRequestDto,
} from '../../types/service-call-request/service-call-request.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setModalContent, setShowModal } from '../utils/utils.slice';
import { ServiceCallAttachmentType } from '../../types/service-call-attachment/service-call-attachment.type';
import {
  setServiceCallProviderDetails,
  setServiceCallProviderDetailsFetchStatus,
  setServiceCallRequestDetails,
  setServiceCallRequestDetailsFetchStatus,
} from './serviceCall.slice';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { ServiceCallCommentType } from '../../types/service-call-comment/service-call-comment.type';

export const postServiceCallTechnician = createAsyncThunk<
  ServiceCallTechnicianType,
  { data: CreateServiceCallTechnicianDto; img?: File },
  { state: RootState }
>('serviceCall/postServiceCallTechnician', async ({ data, img }, thunkAPI) => {
  try {
    const formData = new FormData();
    if (img) formData.append('img', img);
    formData.append('body', JSON.stringify(data));

    const response = await axios.post<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating technician!');
  }
});

export const postServiceCallRequest = createAsyncThunk<
  ServiceCallRequestType,
  { data: CreateServiceCallRequestDto; files: Array<File> },
  { state: RootState }
>('serviceCall/postServiceCallRequest', async ({ data, files }, thunkAPI) => {
  try {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    formData.append('body', JSON.stringify(data));

    const response = await axios.post<ServiceCallRequestType>(
      `${process.env.REACT_APP_API_URL}/service-call-requests`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating request!');
  }
});

export const postServiceCallNote = createAsyncThunk<
  ServiceCallNoteType,
  { data: { note: string; provider?: { id: number }; request?: { id: number } }; fetchDetails: boolean },
  { state: RootState }
>('serviceCall/postServiceCallNote', async ({ data, fetchDetails }, thunkAPI) => {
  try {
    const response = await axios.post<ServiceCallNoteType>(`${process.env.REACT_APP_API_URL}/service-call-notes`, data);

    if (fetchDetails && data.provider) {
      thunkAPI.dispatch(
        getServiceCallProviderDetails({ id: thunkAPI.getState().serviceCallReducer.serviceCallProviderDetails.id })
      );
    }

    if (fetchDetails && data.request) {
      thunkAPI.dispatch(
        getServiceCallRequestDetails({ id: thunkAPI.getState().serviceCallReducer.serviceCallRequestDetails.id })
      );
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating note!');
  }
});

export const postServiceCallComment = createAsyncThunk<
  ServiceCallCommentType,
  { data: { comment: string; request: { id: number } }; fetchDetails: boolean },
  { state: RootState }
>('serviceCall/postServiceCallComment', async ({ data, fetchDetails }, thunkAPI) => {
  try {
    const response = await axios.post<ServiceCallCommentType>(
      `${process.env.REACT_APP_API_URL}/service-call-comments`,
      data
    );

    if (fetchDetails) {
      thunkAPI.dispatch(
        getServiceCallRequestDetails({ id: thunkAPI.getState().serviceCallReducer.serviceCallRequestDetails.id })
      );
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating comment!');
  }
});

export const postServiceCallProvider = createAsyncThunk<
  ServiceCallProviderType,
  { data: CreateServiceCallProviderDto & { note?: string | null }; attachments: Array<File> },
  { state: RootState }
>('serviceCall/postServiceCallProvider', async ({ data, attachments }, thunkAPI) => {
  try {
    const formData = new FormData();
    formData.append('body', JSON.stringify(data));
    if (attachments.length > 0) {
      attachments.forEach(file => {
        formData.append('files', file);
      });
    }

    const response = await axios.post<ServiceCallProviderType>(
      `${process.env.REACT_APP_API_URL}/service-call-providers`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating provider!');
  }
});

export const postServiceCallAttachments = createAsyncThunk<
  ServiceCallAttachmentType[],
  {
    data: { provider?: { id: number }; request?: { id: number } };
    attachments: Array<File>;
    type: 'request' | 'provider';
  },
  { state: RootState }
>('serviceCall/postServiceCallAttachments', async ({ data, type, attachments }, thunkAPI) => {
  try {
    const formData = new FormData();
    formData.append('body', JSON.stringify(data));
    if (attachments.length > 0) {
      attachments.forEach(file => {
        formData.append('files', file);
      });
    }

    const response = await axios.post<ServiceCallAttachmentType[]>(
      `${process.env.REACT_APP_API_URL}/service-call-attachments`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    if (type === 'provider') {
      const details = { ...thunkAPI.getState().serviceCallReducer.serviceCallProviderDetails };
      details.attachments = [...details.attachments, ...response.data];
      thunkAPI.dispatch(setServiceCallProviderDetailsFetchStatus(FETCH_STATUS.PENDING));
      thunkAPI.dispatch(setServiceCallProviderDetails({ ...details }));
      thunkAPI.dispatch(setServiceCallProviderDetailsFetchStatus(FETCH_STATUS.FULFILLED));
    }

    if (type === 'request') {
      const details = { ...thunkAPI.getState().serviceCallReducer.serviceCallRequestDetails };
      details.attachments = [...details.attachments, ...response.data];
      thunkAPI.dispatch(setServiceCallRequestDetailsFetchStatus(FETCH_STATUS.PENDING));
      thunkAPI.dispatch(setServiceCallRequestDetails({ ...details }));
      thunkAPI.dispatch(setServiceCallRequestDetailsFetchStatus(FETCH_STATUS.FULFILLED));
    }

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating attachments!');
  }
});

export const getServiceCallTechniciansList = createAsyncThunk<
  PaginationType<ServiceCallTechnicianType>,
  RouteParamsType,
  { state: RootState }
>('serviceCall/getServiceCallTechniciansList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<ServiceCallTechnicianType>>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching technicians!');
  }
});

export const getServiceCallSiteSurveysList = createAsyncThunk<
  PaginationType<any>,
  RouteParamsType,
  { state: RootState }
>('serviceCall/getServiceCallSiteSurveysList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<any>>(
      `${process.env.REACT_APP_API_URL}/service-call-site-survey${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching site surveys!');
  }
});

export const getServiceCallProvidersList = createAsyncThunk<
  PaginationType<ServiceCallProviderType>,
  RouteParamsType,
  { state: RootState }
>('serviceCall/getServiceCallProvidersList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<ServiceCallProviderType>>(
      `${process.env.REACT_APP_API_URL}/service-call-providers${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching providers!');
  }
});

export const getServiceCallRequestsList = createAsyncThunk<
  PaginationType<ServiceCallRequestType>,
  RouteParamsType,
  { state: RootState }
>('serviceCall/getServiceCallRequestsList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };

    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<ServiceCallRequestType>>(
      `${process.env.REACT_APP_API_URL}/service-call-requests${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching requests!');
  }
});

export const getServiceCallTechnicianDetails = createAsyncThunk<
  ServiceCallTechnicianType,
  { id: number },
  { state: RootState }
>('serviceCall/getServiceCallTechnicianDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching technician!');
  }
});

export const getServiceCallTechnicianRequests = createAsyncThunk<
  Array<ServiceCallRequestType>,
  { id: number },
  { state: RootState }
>('serviceCall/getServiceCallTechnicianRequests', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<Array<ServiceCallRequestType>>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians/${id}/requests`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching requests!');
  }
});

export const getServiceCallProviderDetails = createAsyncThunk<
  ServiceCallProviderType,
  { id: number },
  { state: RootState }
>('serviceCall/getServiceCallProviderDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<ServiceCallProviderType>(
      `${process.env.REACT_APP_API_URL}/service-call-providers/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching provider!');
  }
});

export const getServiceCallProviderRequests = createAsyncThunk<
  Array<ServiceCallRequestType>,
  { id: number },
  { state: RootState }
>('serviceCall/getServiceCallProviderRequests', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<Array<ServiceCallRequestType>>(
      `${process.env.REACT_APP_API_URL}/service-call-providers/${id}/requests`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching requests!');
  }
});

export const getServiceCallRequestDetails = createAsyncThunk<
  ServiceCallRequestType,
  { id: number },
  { state: RootState }
>('serviceCall/getServiceCallRequestDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<ServiceCallRequestType>(
      `${process.env.REACT_APP_API_URL}/service-call-requests/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching request!');
  }
});

export const patchServiceCallTechnician = createAsyncThunk<
  ServiceCallTechnicianType,
  { id: number; data: UpdateServiceCallTechnicianDto; img?: File },
  { state: RootState }
>('serviceCall/patchServiceCallTechnician', async ({ data, id, img }, thunkAPI) => {
  try {
    const formData = new FormData();
    if (img) formData.append('img', img);
    formData.append('body', JSON.stringify(data));

    const response = await axios.patch<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians/${id}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating technician!');
  }
});

export const patchServiceCallRequest = createAsyncThunk<
  ServiceCallRequestType,
  { id: number; data: UpdateServiceCallRequestDto },
  { state: RootState }
>('serviceCall/patchServiceCallRequest', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<ServiceCallRequestType>(
      `${process.env.REACT_APP_API_URL}/service-call-requests/${id}`,
      data
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating request!');
  }
});

export const patchServiceCallProvider = createAsyncThunk<
  ServiceCallProviderType,
  {
    id: number;
    data: UpdateServiceCallProviderDto;
  },
  { state: RootState }
>('serviceCall/patchServiceCallProvider', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<ServiceCallProviderType>(
      `${process.env.REACT_APP_API_URL}/service-call-providers/${id}`,
      data
    );

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating provider!');
  }
});

export const deleteServiceCallTechnician = createAsyncThunk<
  ServiceCallTechnicianType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('serviceCall/deleteServiceCallTechnician', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallTechnicianType>(
      `${process.env.REACT_APP_API_URL}/service-call-technicians/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(
        getServiceCallTechniciansList(thunkAPI.getState().serviceCallReducer.serviceCallTechniciansRouteParams)
      );
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting technician!');
  }
});

export const deleteServiceCallProvider = createAsyncThunk<
  ServiceCallProviderType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('serviceCall/deleteServiceCallProvider', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallProviderType>(
      `${process.env.REACT_APP_API_URL}/service-call-providers/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(
        getServiceCallProvidersList(thunkAPI.getState().serviceCallReducer.serviceCallProvidersRouteParams)
      );
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting provider!');
  }
});

export const deleteServiceCallRequest = createAsyncThunk<
  ServiceCallRequestType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('serviceCall/deleteServiceCallRequest', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallRequestType>(
      `${process.env.REACT_APP_API_URL}/service-call-requests/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(
        getServiceCallRequestsList(thunkAPI.getState().serviceCallReducer.serviceCallRequestsRouteParams)
      );
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting request!');
  }
});

export const deleteServiceCallNote = createAsyncThunk<
  ServiceCallNoteType,
  { id: number; fetchDetails: boolean; type: 'request' | 'provider' },
  { state: RootState }
>('serviceCall/deleteServiceCallNote', async ({ id, fetchDetails, type }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallNoteType>(
      `${process.env.REACT_APP_API_URL}/service-call-notes/${id}`
    );

    if (fetchDetails && type === 'request') {
      thunkAPI.dispatch(
        getServiceCallRequestDetails({ id: thunkAPI.getState().serviceCallReducer.serviceCallRequestDetails.id })
      );
    }

    if (fetchDetails && type === 'provider') {
      thunkAPI.dispatch(
        getServiceCallProviderDetails({ id: thunkAPI.getState().serviceCallReducer.serviceCallProviderDetails.id })
      );
    }

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting note!');
  }
});

export const deleteServiceCallComment = createAsyncThunk<
  ServiceCallCommentType,
  { id: number; fetchDetails: boolean },
  { state: RootState }
>('serviceCall/deleteServiceCallComment', async ({ id, fetchDetails }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallCommentType>(
      `${process.env.REACT_APP_API_URL}/service-call-comments/${id}`
    );

    if (fetchDetails) {
      thunkAPI.dispatch(
        getServiceCallRequestDetails({ id: thunkAPI.getState().serviceCallReducer.serviceCallRequestDetails.id })
      );
    }

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting comment!');
  }
});

export const deleteServiceCallAttachment = createAsyncThunk<
  ServiceCallAttachmentType,
  { id: number; type: 'provider' | 'request' },
  { state: RootState }
>('serviceCall/deleteServiceCallAttachment', async ({ id, type }, thunkAPI) => {
  try {
    const response = await axios.delete<ServiceCallAttachmentType>(
      `${process.env.REACT_APP_API_URL}/service-call-attachments/${id}`
    );

    if (type === 'provider') {
      const details = { ...thunkAPI.getState().serviceCallReducer.serviceCallProviderDetails };
      details.attachments = details.attachments.filter(a => a.id !== id);
      thunkAPI.dispatch(setServiceCallProviderDetailsFetchStatus(FETCH_STATUS.PENDING));
      thunkAPI.dispatch(setServiceCallProviderDetails({ ...details }));
      thunkAPI.dispatch(setServiceCallProviderDetailsFetchStatus(FETCH_STATUS.FULFILLED));
    }

    if (type === 'request') {
      const details = { ...thunkAPI.getState().serviceCallReducer.serviceCallRequestDetails };
      details.attachments = details.attachments.filter(a => a.id !== id);
      thunkAPI.dispatch(setServiceCallRequestDetailsFetchStatus(FETCH_STATUS.PENDING));
      thunkAPI.dispatch(setServiceCallRequestDetails({ ...details }));
      thunkAPI.dispatch(setServiceCallRequestDetailsFetchStatus(FETCH_STATUS.FULFILLED));
    }

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting note!');
  }
});

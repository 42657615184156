import { createSlice } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import { DIDDetailsType, DIDsListItemType, DIDType } from '../../types/did/did.type';
import {
  deleteDIDDetails,
  getDIDAvailability,
  getDIDDetails,
  getDIDRouting,
  getDIDsList,
  getProvisionDIDE911,
  getVerifyDIDE911,
  patchDIDCnam,
  patchDIDDetails,
  patchDIDE911,
  postDIDDetails,
  postDIDFixRouting,
  postDIDs,
  postPBXExtensionDID,
  postTemporaryDID,
} from './dids.action';

export interface DIDsState {
  didsList: null | PaginationType<DIDsListItemType>;
  didsListFetchStatus: FETCH_STATUS;
  didsRouteParams: RouteParamsType;
  didDetails: null | DIDDetailsType;
  didDetailsFetchStatus: FETCH_STATUS;
  didDetailsPostStatus: FETCH_STATUS;
  didDetailsPatchStatus: FETCH_STATUS;
  didDetailsDeleteStatus: FETCH_STATUS;
  didFixRoutingPostStatus: FETCH_STATUS;
  didRoutingFetchStatus: FETCH_STATUS;
  didRouting: unknown | null;
  didsPostStatus: FETCH_STATUS;
  didsBulk: null | Array<Pick<DIDType, 'did' | 'description' | 'did_authid' | 'did_password' | 'did_username'>>;
  didTemporaryPostStatus: FETCH_STATUS;
  didCNAMPatchStatus: FETCH_STATUS;
  didE911PatchStatus: FETCH_STATUS;
  verifyDIDE911FetchStatus: FETCH_STATUS;
  provisionDIDE911FetchStatus: FETCH_STATUS;
  pbxExtensionDIDPostStatus: FETCH_STATUS;
  didAvailabilityFetchStatus: FETCH_STATUS;
  didAvailability: null | { dids: Array<{ object: DIDType; did: string }>; available: boolean };
}

export const initialDidsRouteParams = {
  page: 1,
  limit: 10,
  order: '-did.id',
  q: '',
  status: '',
  country_id: '',
  country_category: '',
  is_system_did: 'all',
  e911: 'all',
  provider: '',
  profile: '',
  system_id: '',
};

const INIT_STATE: DIDsState = {
  didsRouteParams: initialDidsRouteParams,
  didsList: null,
  didsListFetchStatus: FETCH_STATUS.NULL,
  didDetails: null,
  didDetailsFetchStatus: FETCH_STATUS.NULL,
  didDetailsPostStatus: FETCH_STATUS.NULL,
  didDetailsPatchStatus: FETCH_STATUS.NULL,
  didDetailsDeleteStatus: FETCH_STATUS.NULL,
  didFixRoutingPostStatus: FETCH_STATUS.NULL,
  didRoutingFetchStatus: FETCH_STATUS.NULL,
  didAvailabilityFetchStatus: FETCH_STATUS.NULL,
  didAvailability: null,
  didRouting: null,
  didsPostStatus: FETCH_STATUS.NULL,
  didsBulk: null,
  didTemporaryPostStatus: FETCH_STATUS.NULL,
  didCNAMPatchStatus: FETCH_STATUS.NULL,
  didE911PatchStatus: FETCH_STATUS.NULL,
  verifyDIDE911FetchStatus: FETCH_STATUS.NULL,
  provisionDIDE911FetchStatus: FETCH_STATUS.NULL,
  pbxExtensionDIDPostStatus: FETCH_STATUS.NULL,
};

const didsSlice = createSlice({
  name: 'dids',
  initialState: INIT_STATE,
  reducers: {
    setDIDsList: (state, action) => {
      state.didsList = action.payload;
    },
    setDIDsListFetchStatus: (state, action) => {
      state.didsListFetchStatus = action.payload;
    },
    setDIDDetails: (state, action) => {
      state.didDetails = action.payload;
    },
    setDIDDetailsFetchStatus: (state, action) => {
      state.didDetailsFetchStatus = action.payload;
    },
    setDIDDetailsPostStatus: (state, action) => {
      state.didDetailsPostStatus = action.payload;
    },
    setDIDDetailsPatchStatus: (state, action) => {
      state.didDetailsPatchStatus = action.payload;
    },
    setDIDDetailsDeleteStatus: (state, action) => {
      state.didDetailsDeleteStatus = action.payload;
    },
    setDIDsPostStatus: (state, action) => {
      state.didsPostStatus = action.payload;
    },
    setDIDsBulk: (state, action) => {
      state.didsBulk = action.payload;
    },
    setDIDFixRoutingPostStatus: (state, action) => {
      state.didFixRoutingPostStatus = action.payload;
    },
    setDIDRoutingFetchStatus: (state, action) => {
      state.didRoutingFetchStatus = action.payload;
    },
    setDIDRouting: (state, action) => {
      state.didRouting = action.payload;
    },
    setDIDTemporaryPostStatus: (state, action) => {
      state.didTemporaryPostStatus = action.payload;
    },
    setDIDCNAMPatchStatus: (state, action) => {
      state.didCNAMPatchStatus = action.payload;
    },
    setDIDE911PatchStatus: (state, action) => {
      state.didE911PatchStatus = action.payload;
    },
    setVerifyDIDE911FetchStatus: (state, action) => {
      state.verifyDIDE911FetchStatus = action.payload;
    },
    setProvisionDIDE911FetchStatus: (state, action) => {
      state.provisionDIDE911FetchStatus = action.payload;
    },
    setPBXExtensionDIDPostStatus: (state, action) => {
      state.pbxExtensionDIDPostStatus = action.payload;
    },
    setDIDAvailabilityFetchStatus: (state, action) => {
      state.didAvailabilityFetchStatus = action.payload;
    },
    setDIDAvailability: (state, action) => {
      state.didAvailability = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'dids') {
        state.didsRouteParams = { ...state.didsRouteParams, ...payload.data };
      }
    });

    // CREATE DIDs DETAILS
    builder.addCase(postDIDs.pending, state => {
      state.didsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postDIDs.rejected, (state, action) => {
      state.didsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postDIDs.fulfilled, state => {
      state.didsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('DIDs created successfully');
      });
    });

    // CREATE DIDs DETAILS
    builder.addCase(postDIDDetails.pending, state => {
      state.didDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postDIDDetails.rejected, (state, action) => {
      state.didDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postDIDDetails.fulfilled, state => {
      state.didDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('DID created successfully');
      });
    });

    // GET DIDs LIST
    builder.addCase(getDIDsList.pending, state => {
      state.didsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDIDsList.rejected, (state, action) => {
      if (action.payload) {
        state.didsListFetchStatus = FETCH_STATUS.REJECTED;
        // state.didsList = null;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getDIDsList.fulfilled, (state, action) => {
      state.didsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.didsList = action.payload;
    });

    // GET DIDs DETAILS
    builder.addCase(getDIDDetails.pending, state => {
      state.didDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDIDDetails.rejected, (state, action) => {
      state.didDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.didDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getDIDDetails.fulfilled, (state, action) => {
      state.didDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.didDetails = action.payload;
    });

    // PATCH DIDs DETAILS
    builder.addCase(patchDIDDetails.pending, state => {
      state.didDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchDIDDetails.rejected, (state, action) => {
      state.didDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchDIDDetails.fulfilled, state => {
      state.didDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('DID updated successfully');
      });
    });

    // DELETE DIDs DETAILS
    builder.addCase(deleteDIDDetails.pending, state => {
      state.didDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteDIDDetails.rejected, (state, action) => {
      state.didDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteDIDDetails.fulfilled, state => {
      state.didDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('DID deleted successfully');
      });
    });

    // POST FIX DIDs ROUTING
    builder.addCase(postDIDFixRouting.pending, state => {
      state.didFixRoutingPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postDIDFixRouting.rejected, (state, action) => {
      state.didFixRoutingPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postDIDFixRouting.fulfilled, state => {
      state.didFixRoutingPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('DID has been routed successfully');
      });
    });

    // GET DIDs ROUTING
    builder.addCase(getDIDRouting.pending, state => {
      state.didRoutingFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDIDRouting.rejected, (state, action) => {
      state.didRoutingFetchStatus = FETCH_STATUS.REJECTED;
      state.didRouting = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getDIDRouting.fulfilled, (state, action) => {
      state.didRoutingFetchStatus = FETCH_STATUS.FULFILLED;
      state.didRouting = action.payload;
    });

    // POST DIDs TEMPORARY
    builder.addCase(postTemporaryDID.pending, state => {
      state.didTemporaryPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postTemporaryDID.rejected, (state, action) => {
      state.didTemporaryPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postTemporaryDID.fulfilled, state => {
      state.didTemporaryPostStatus = FETCH_STATUS.FULFILLED;
    });

    // PATCH DID CNAM
    builder.addCase(patchDIDCnam.pending, state => {
      state.didCNAMPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchDIDCnam.rejected, (state, action) => {
      state.didCNAMPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchDIDCnam.fulfilled, state => {
      state.didCNAMPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('DID CNAM updated successfully');
      });
    });

    // PATCH DID E911
    builder.addCase(patchDIDE911.pending, state => {
      state.didE911PatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchDIDE911.rejected, (state, action) => {
      state.didE911PatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchDIDE911.fulfilled, state => {
      state.didE911PatchStatus = FETCH_STATUS.FULFILLED;
    });

    // GET VERIFY DID E911
    builder.addCase(getVerifyDIDE911.pending, state => {
      state.verifyDIDE911FetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getVerifyDIDE911.rejected, (state, action) => {
      state.verifyDIDE911FetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getVerifyDIDE911.fulfilled, state => {
      state.verifyDIDE911FetchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('DID E911 verified successfully');
      });
    });

    // GET PROVISION DID E911
    builder.addCase(getProvisionDIDE911.pending, state => {
      state.provisionDIDE911FetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getProvisionDIDE911.rejected, (state, action) => {
      state.provisionDIDE911FetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getProvisionDIDE911.fulfilled, state => {
      state.provisionDIDE911FetchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('The E911 address is being updated, please come back in a moment.');
      });
    });

    // POST PBX EXTENSION DID
    builder.addCase(postPBXExtensionDID.pending, state => {
      state.pbxExtensionDIDPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPBXExtensionDID.rejected, (state, action) => {
      state.pbxExtensionDIDPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postPBXExtensionDID.fulfilled, state => {
      state.pbxExtensionDIDPostStatus = FETCH_STATUS.FULFILLED;
    });

    // GET DID AVAILABILITY
    builder.addCase(getDIDAvailability.pending, state => {
      state.didAvailabilityFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDIDAvailability.rejected, (state, action) => {
      state.didAvailabilityFetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getDIDAvailability.fulfilled, (state, action) => {
      state.didAvailabilityFetchStatus = FETCH_STATUS.FULFILLED;
      state.didAvailability = action.payload;
    });
  },
});

export const {
  setDIDDetailsDeleteStatus,
  setDIDDetailsFetchStatus,
  setDIDDetailsPatchStatus,
  setDIDDetailsPostStatus,
  setDIDDetails,
  setDIDsListFetchStatus,
  setDIDsPostStatus,
  setDIDsBulk,
  setDIDsList,
  setDIDRoutingFetchStatus,
  setDIDRouting,
  setDIDFixRoutingPostStatus,
  setDIDTemporaryPostStatus,
  setProvisionDIDE911FetchStatus,
  setVerifyDIDE911FetchStatus,
  setDIDE911PatchStatus,
  setDIDCNAMPatchStatus,
  setPBXExtensionDIDPostStatus,
  setDIDAvailabilityFetchStatus,
  setDIDAvailability,
} = didsSlice.actions;

export default didsSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setModalContent, setShowModal } from '../utils/utils.slice';
import { DataPlanType } from '../../types/data-plan/data-plan.type';
import { DataPlanTypeType } from '../../types/data-plan/data-plan-type/data-plan-type.type';

export const postDataPlanDetails = createAsyncThunk<DataPlanType, { data: unknown }, { state: RootState }>(
  'dataPlans/postDataPlanDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<DataPlanType>(`${process.env.REACT_APP_API_URL}/data-plans`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating data plan!');
    }
  }
);

export const getDataPlansList = createAsyncThunk<
  PaginationType<DataPlanType>,
  RouteParamsType & {
    controller: AbortController;
  },
  { state: RootState }
>('dataPlans/getDataPlansList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs,
      thunkAPI.getState().sharedReducer.selectedCountry
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<DataPlanType>>(
      `${process.env.REACT_APP_API_URL}/data-plans${queryString ? `?${queryString}` : ''}`,
      { signal: controller.signal }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching data plans!');
  }
});

export const getDataPlanDetails = createAsyncThunk<DataPlanType, { id: number }, { state: RootState }>(
  'dataPlans/getDataPlanDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<DataPlanType>(`${process.env.REACT_APP_API_URL}/data-plans/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching data plan!');
    }
  }
);

export const patchDataPlanDetails = createAsyncThunk<DataPlanType, { id: number; data: unknown }, { state: RootState }>(
  'dataPlans/patchDataPlanDetails',
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.patch<DataPlanType>(`${process.env.REACT_APP_API_URL}/data-plans/${id}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating data plan!');
    }
  }
);

export const deleteDataPlanDetails = createAsyncThunk<
  DataPlanType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('dataPlans/deleteDataPlanDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<DataPlanType>(`${process.env.REACT_APP_API_URL}/data-plans/${id}`);
    if (fetchList) thunkAPI.dispatch(getDataPlansList(thunkAPI.getState().dataPlansReducer.dataPlansRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting data plan!');
  }
});

// DATA PLAN TYPES

export const postDataPlanTypeDetails = createAsyncThunk<DataPlanTypeType, { data: unknown }, { state: RootState }>(
  'dataPlanTypes/postDataPlanTypeDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<DataPlanTypeType>(`${process.env.REACT_APP_API_URL}/data-plans/types`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating data plan type!');
    }
  }
);

export const getDataPlanTypesList = createAsyncThunk<
  PaginationType<DataPlanTypeType>,
  RouteParamsType & { controller: AbortController },
  { state: RootState }
>('dataPlanTypes/getDataPlanTypesList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<DataPlanTypeType>>(
      `${process.env.REACT_APP_API_URL}/data-plans/types${queryString ? `?${queryString}` : ''}`,
      { signal: controller.signal }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching data plan types!');
  }
});

export const getDataPlanTypeDetails = createAsyncThunk<DataPlanTypeType, { id: number }, { state: RootState }>(
  'dataPlanTypes/getDataPlanTypeDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<DataPlanTypeType>(`${process.env.REACT_APP_API_URL}/data-plans/types/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching data plan type!');
    }
  }
);

export const patchDataPlanTypeDetails = createAsyncThunk<
  DataPlanTypeType,
  { id: number; data: unknown },
  {
    state: RootState;
  }
>('dataPlanTypes/patchDataPlanTypeDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<DataPlanTypeType>(
      `${process.env.REACT_APP_API_URL}/data-plans/types/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating data plan type!');
  }
});

export const deleteDataPlanTypeDetails = createAsyncThunk<
  DataPlanTypeType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('dataPlanTypes/deleteDataPlanTypeDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<DataPlanTypeType>(`${process.env.REACT_APP_API_URL}/data-plans/types/${id}`);
    if (fetchList)
      thunkAPI.dispatch(getDataPlanTypesList(thunkAPI.getState().dataPlansReducer.dataPlanTypesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting data plan type!');
  }
});

import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { createSlice } from '@reduxjs/toolkit';
import { NotificationListItemType } from '../../types/notification/notification.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { getNotificationsList } from './notifications.action';

export interface NotificationsState {
  notificationsList: null | PaginationType<NotificationListItemType>;
  notificationsListFetchStatus: FETCH_STATUS;
  notificationsRouteParams: RouteParamsType;
}

export const initialNotificationsRouteParams = {
  page: 1,
  limit: 10,
  order: '-notification.id',
  q: '',
};

const INIT_STATE: NotificationsState = {
  notificationsRouteParams: initialNotificationsRouteParams,
  notificationsList: null,
  notificationsListFetchStatus: FETCH_STATUS.NULL,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: INIT_STATE,
  reducers: {
    setNotificationsList: (state, action) => {
      state.notificationsList = action.payload;
    },
    setNotificationsListFetchStatus: (state, action) => {
      state.notificationsListFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'notifications') {
        state.notificationsRouteParams = { ...state.notificationsRouteParams, ...payload.data };
      }
    });
    // GET NOTIFICATIONS LIST
    builder.addCase(getNotificationsList.pending, state => {
      state.notificationsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getNotificationsList.rejected, (state, action) => {
      if (action.payload) {
        state.notificationsListFetchStatus = FETCH_STATUS.REJECTED;
        // state.notificationsList = null;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getNotificationsList.fulfilled, (state, action) => {
      state.notificationsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.notificationsList = action.payload;
    });
  },
});

export const { setNotificationsList, setNotificationsListFetchStatus } = notificationsSlice.actions;
export default notificationsSlice.reducer;

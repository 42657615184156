import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { ReleaseNoteListItemType, ReleaseNoteType } from '../../types/release-notes/release-notes';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { setModalContent, setShowModal } from '../utils/utils.slice';

export const postReleaseNoteDetails = createAsyncThunk<
  ReleaseNoteType,
  { data: any; fetchList: boolean; userId: string },
  { state: RootState }
>('releaseNotes/postReleaseNoteDetails', async ({ data, fetchList, userId }, thunkAPI) => {
  try {
    const isReleased = !data.scheduledReleaseDate;

    const payload = {
      title: data.title,
      versionNumber: data.versionNumber,
      releaseDate: data.scheduledReleaseDate || new Date().toISOString(),
      scheduledReleaseDate: data.scheduledReleaseDate || null,
      updateType: data.updateType,
      description: data.description,
      targetDevices: data.targetDevices || [],
      newFeatures: data.newFeatures || [],
      bugFixes: data.bugFixes || [],
      microservices: data.microservices || [],
      performanceImprovements: data.performanceImprovements || [],
      backupRequirement: !!data.backupRequirement,
      backupDetails: data.backupDetails || '',
      hardwareRequirements: data.hardwareRequirements || [],
      softwareRequirements: data.softwareRequirements || [],
      preparationInstructions: data.preparationInstructions || '',
      updateProcess: data.updateProcess || '',
      postUpdateInstructions: data.postUpdateInstructions || '',
      knownIssues: data.knownIssues || [],
      supportEmail: data.supportEmail || '',
      phoneNumber: data.phoneNumber || '',
      additionalNotes: data.additionalNotes || '',
      category: data.category || '',
      isReleased: !data.scheduledReleaseDate,
      isPublic: !!data.isPublic,
      isInternal: !!data.isInternal,
      isAdminOnly: !!data.isAdminOnly,
      mainDeveloper: data.mainDeveloper || '',
    };

    const formData = new FormData();
    if (Array.isArray(data.media)) {
      data.media.forEach((file: File, index: number) => {
        if (file instanceof File) {
          formData.append('files', file);
        } else {
          console.error(`Invalid file at index ${index}:`, file);
        }
      });
    } else if (data.media instanceof File) {
      formData.append('files', data.media);
    }

    formData.append('body', JSON.stringify(payload));
    const response = await axios.post<ReleaseNoteType>(`${process.env.REACT_APP_API_URL}/release-notes`, formData);

    if (isReleased) {
      await axios.post(`${process.env.REACT_APP_API_URL}/release-notifications/notify-all`, {
        releaseNoteId: response.data.id,
      });
    }

    if (fetchList)
      thunkAPI.dispatch(getReleaseNotesList(thunkAPI.getState().releaseNotesReducer.releaseNotesRouteParams));
    thunkAPI.dispatch(getNotifications({ userId }));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data.message || 'Error during creating release note!');
  }
});

export const getReleaseNotesList = createAsyncThunk<
  PaginationType<ReleaseNoteListItemType>,
  Partial<RouteParamsType> & { controller?: AbortController },
  { state: RootState }
>('releaseNotes/getReleaseNotesList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    const queryParams = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== '')
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    const url = `${process.env.REACT_APP_API_URL}/release-notes${queryParams ? `?${queryParams}` : ''}`;

    const response = await axios.get<PaginationType<ReleaseNoteListItemType>>(
      url,
      controller ? { signal: controller.signal } : undefined
    );
    return response.data;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || 'Error fetching release notes!';
    console.error('Error fetching release notes:', errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const getReleaseNoteDetails = createAsyncThunk<ReleaseNoteType, { id: number }, { state: RootState }>(
  'releaseNotes/getReleaseNoteDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<ReleaseNoteType>(`${process.env.REACT_APP_API_URL}/release-notes/${id}`);
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || 'Error during fetching release note';
      console.error('Error fetching release notes:', errorMessage);
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const patchReleaseNoteDetails = createAsyncThunk<
  ReleaseNoteType,
  { id: number; data: any; fetchList: boolean },
  { state: RootState }
>('releaseNotes/patchReleaseNoteDetails', async ({ data, id, fetchList }, thunkAPI) => {
  try {
    const payload = {
      title: data.title,
      versionNumber: data.versionNumber,
      releaseDate: data.releaseDate,
      scheduledReleaseDate: data.scheduledReleaseDate || null,
      updateType: data.updateType,
      description: data.description,
      targetDevices: data.targetDevices || [],
      newFeatures: data.newFeatures || [],
      bugFixes: data.bugFixes || [],
      microservices: data.microservices || [],
      performanceImprovements: data.performanceImprovements || [],
      backupRequirement: data.backupRequirement,
      backupDetails: data.backupDetails || null,
      hardwareRequirements: data.hardwareRequirements || [],
      softwareRequirements: data.softwareRequirements || [],
      preparationInstructions: data.preparationInstructions || '',
      updateProcess: data.updateProcess || '',
      postUpdateInstructions: data.postUpdateInstructions || '',
      knownIssues: data.knownIssues || [],
      supportEmail: data.supportEmail || null,
      phoneNumber: data.phoneNumber || null,
      additionalNotes: data.additionalNotes || null,
      category: data.category || null,
      isReleased: data.isReleased || false,
      isPublic: data.isPublic || false,
      isInternal: data.isInternal || false,
      isAdminOnly: data.isAdminOnly || false,
      mainDeveloper: data.mainDeveloper || null,
      supportNumber: data.supportNumber || null,
      supportInformation: data.supportInformation || null,
    };

    const formData = new FormData();
    if (Array.isArray(data.media)) {
      data.media.forEach((file, index) => {
        if (file instanceof File) {
          formData.append('files', file);
        } else {
          console.error(`Invalid file at index ${index}:`, file);
        }
      });
    } else if (data.media instanceof File) {
      formData.append('files', data.media);
    }
    formData.append('body', JSON.stringify(payload));

    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/release-notes/${id}`,
      payload, // JSON payload
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (fetchList) {
      thunkAPI.dispatch(getReleaseNotesList(thunkAPI.getState().releaseNotesReducer.releaseNotesRouteParams));
    }

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || 'Error during updating release note!';
    console.error('Error updating release note:', errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const deleteReleaseNoteDetails = createAsyncThunk<
  ReleaseNoteType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('releaseNotes/deleteReleaseNoteDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<ReleaseNoteType>(`${process.env.REACT_APP_API_URL}/release-notes/${id}`);
    if (fetchList)
      thunkAPI.dispatch(getReleaseNotesList(thunkAPI.getState().releaseNotesReducer.releaseNotesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || 'Error during deleting release note!';
    console.error('Error fetching release notes:', errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const getReleaseNoteMicroservicesList = createAsyncThunk<
  Array<{ id: number; name: string }>,
  void,
  { state: RootState }
>('releaseNotes/getReleaseNoteMicroservicesList', async (_, thunkAPI) => {
  try {
    const response = await axios.get<Array<{ id: number; name: string }>>(
      `${process.env.REACT_APP_API_URL}/release-notes/microservices`
    );
    return response.data;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || 'Error during fetching release note microservices!';
    console.error('Error fetching release notes:', errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const markAllNotificationsAsRead = createAsyncThunk<
  Array<{ id: number; name: string }>,
  { userId: string },
  { state: RootState }
>('releaseNotes/markAllNotificationsAsRead', async ({ userId }, thunkAPI) => {
  try {
    const response = await axios.patch<Array<{ id: number; name: string }>>(
      `${process.env.REACT_APP_API_URL}/release-notifications/mark-all-as-read`,
      undefined
    );
    thunkAPI.dispatch(getNotifications({ userId }));
    return response.data;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || 'Error duringmarking all notifications as read!';
    console.error('Error fetching release notes:', errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const markNotificationAsRead = createAsyncThunk<
  Array<{ id: number; name: string }>,
  { userId: string; notificationId: string },
  { state: RootState }
>('releaseNotes/markNotificationAsRead', async ({ userId, notificationId }, thunkAPI) => {
  try {
    const response = await axios.patch<Array<{ id: number; name: string }>>(
      `${process.env.REACT_APP_API_URL}/release-notifications/${notificationId}/mark-as-read`,
      undefined
    );
    thunkAPI.dispatch(getNotifications({ userId }));
    return response.data;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || 'Error during marking notification as read!';
    console.error('Error fetching release notes:', errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const getNotifications = createAsyncThunk<
  { notifications: Array<{ releaseNote: ReleaseNoteType; isRead: boolean }>; unreadCount: number },
  { userId: string },
  { state: RootState }
>('releaseNotes/getNotifications', async ({ userId }, thunkAPI) => {
  try {
    const response = await axios.get<{
      notifications: Array<{ releaseNote: ReleaseNoteType; isRead: boolean }>;
      unreadCount: number;
    }>(`${process.env.REACT_APP_API_URL}/release-notifications`, {
      params: { userId },
    });
    return response.data;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || 'Error during fetching notifications!';
    console.error('Error fetching release notes:', errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

import { PermissionArrayType } from '../../../types/other';
import { PERMISSIONROLECONSTANTS } from '../../../features/utils/utils.action';

export interface SiteSurveyForm {
  id: string | null;
  organization: null | { id: number };
  location: null | { id: number };
  surveyDate: string;
}

export const serviceCallSiteSurveyPermissions: PermissionArrayType = {
  CREATE: [PERMISSIONROLECONSTANTS.superadmin],
  READ: [PERMISSIONROLECONSTANTS.superadmin],
  UPDATE: [PERMISSIONROLECONSTANTS.superadmin],
  DELETE: [PERMISSIONROLECONSTANTS.superadmin],
};

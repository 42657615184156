import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import { BucketListItemType, BucketType, CreateBucketDto, UpdateBucketDto } from '../../types/bucket/bucket.type';
import {
  CreateBulkSecureAlarmDIDsDto,
  SecureAlarmDIDListItemType,
} from '../../types/SecureAlarmDID/secureAlarmDID.type';
import { setShowModal, setModalContent } from '../utils/utils.slice';

export const getBucketsList = createAsyncThunk<
  PaginationType<BucketListItemType>,
  RouteParamsType & { controller?: AbortController },
  { state: RootState }
>('secureAlarm/getBucketsList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<BucketListItemType>>(
      `${process.env.REACT_APP_API_URL}/secure-alarm/bucket${queryString ? `?${queryString}` : ''}`,
      controller ? { signal: controller.signal } : undefined
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching buckets!');
  }
});

export const getBucketDetails = createAsyncThunk<BucketType, { id: number }, { state: RootState }>(
  'secureAlarm/getBucketDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<BucketType>(`${process.env.REACT_APP_API_URL}/secure-alarm/bucket/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching bucket details!');
    }
  }
);

export const deleteBucketDetails = createAsyncThunk<
  BucketType,
  { id: number; fetchList: boolean },
  {
    state: RootState;
  }
>('secureAlarm/deleteBucketDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<BucketType>(`${process.env.REACT_APP_API_URL}/secure-alarm/bucket/${id}`);
    if (fetchList) thunkAPI.dispatch(getBucketsList(thunkAPI.getState().secureAlarmReducer.bucketsRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting bucket details!');
  }
});

export const postBucketDetails = createAsyncThunk<void, { data: CreateBucketDto }, { state: RootState }>(
  'secureAlarm/postBucketDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<void>(`${process.env.REACT_APP_API_URL}/secure-alarm/bucket`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating bucket!');
    }
  }
);

export const patchBucketDetails = createAsyncThunk<void, { data: UpdateBucketDto; id: number }, { state: RootState }>(
  'secureAlarm/patchBucketDetails',
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.patch<void>(`${process.env.REACT_APP_API_URL}/secure-alarm/bucket/${id}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating bucket!');
    }
  }
);

export const getSecureAlarmDIDsList = createAsyncThunk<
  PaginationType<SecureAlarmDIDListItemType>,
  RouteParamsType & { controller: AbortController },
  { state: RootState }
>('secureAlarm/getSecureAlarmDIDsList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    if (params.available !== 'false' && params.available !== 'true') {
      delete params.available;
    }

    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<PaginationType<SecureAlarmDIDListItemType>>(
      `${process.env.REACT_APP_API_URL}/secure-alarm/did${queryString ? `?${queryString}` : ''}`,
      { signal: controller.signal }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching DIDs!');
  }
});

export const postSecureAlarmDIDs = createAsyncThunk<
  PaginationType<SecureAlarmDIDListItemType>,
  { data: CreateBulkSecureAlarmDIDsDto },
  { state: RootState }
>('secureAlarm/postSecureAlarmDIDs', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<PaginationType<SecureAlarmDIDListItemType>>(
      `${process.env.REACT_APP_API_URL}/secure-alarm/did`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating DIDs!');
  }
});

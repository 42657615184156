import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { createSlice } from '@reduxjs/toolkit';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { getIssueTrackerList } from '../logs/logs.action';
import snackbarUtils from '../../utils/SnackbarUtils';
import { getAlarmsList } from './alarm.action';

export interface AlarmsState {
  accessToken: string | null;
  alarmsList: null | unknown;
  alarmsListFetchStatus: FETCH_STATUS;
  alarmsListRouteParams: RouteParamsType;
}

export const alarmsInitialRouteParams = {
  page: 1,
  limit: 10,
  order: 'obj.id',
  q: '',
  type: 'contact-id',
};

const INIT_STATE: AlarmsState = {
  accessToken: null,
  alarmsList: null,
  alarmsListFetchStatus: FETCH_STATUS.NULL,
  alarmsListRouteParams: alarmsInitialRouteParams,
};

const alarmsSlice = createSlice({
  name: 'alarms',
  initialState: INIT_STATE,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setAlarmsList: (state, action) => {
      state.alarmsList = action.payload;
    },
    setAlarmsListFetchStatus: (state, action) => {
      state.alarmsListFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'alarms') {
        state.alarmsListRouteParams = { ...state.alarmsListRouteParams, ...payload.data };
      }
    });

    builder.addCase(getAlarmsList.pending, state => {
      state.alarmsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getAlarmsList.rejected, (state, action) => {
      state.alarmsListFetchStatus = FETCH_STATUS.REJECTED;
      state.alarmsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getAlarmsList.fulfilled, (state, action) => {
      state.alarmsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.alarmsList = action.payload;
    });
  },
});

export const { setAccessToken, setAlarmsListFetchStatus, setAlarmsList } = alarmsSlice.actions;

export default alarmsSlice.reducer;

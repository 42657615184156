import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncTaskType } from '../../types/async-task/async-task.type';
import { RootState } from '../../redux/Store';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setShowModal, setModalContent } from '../utils/utils.slice';
import {
  CreateLocationDto,
  LocationDetailsType,
  LocationListItemType,
  UpdateLocationDto,
} from '../../types/location/location.type';
import {
  CreateLocationPanelInfoDto,
  LocationPanelInfoType,
  UpdateLocationPanelInfoDto,
} from '../../types/location/location-panel-info/location-panel-info.type';
import { PBXListItemType } from '../../types/pbx/PBXListItem.type';
import { DIDsListItemType } from '../../types/did/did.type';
import { PbxExtensionListItemType } from '../../types/pbx-extension/pbx-extension.type';

export const postLocations = createAsyncThunk<
  AsyncTaskType,
  { data: { organization: { id: number }; locations: Array<any> } },
  { state: RootState }
>('locations/postLocations', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<AsyncTaskType>(`${process.env.REACT_APP_API_URL}/location/bulk`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating locations!');
  }
});

export const postLocationDetails = createAsyncThunk<
  LocationDetailsType,
  { data: CreateLocationDto },
  { state: RootState }
>('locations/postLocationDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<LocationDetailsType>(`${process.env.REACT_APP_API_URL}/location`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating location!');
  }
});

export const getLocationsList = createAsyncThunk<
  PaginationType<LocationListItemType>,
  RouteParamsType & { controller: AbortController },
  { state: RootState }
>('locations/getLocationsList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      null,
      thunkAPI.getState().sharedReducer.includeSuborgs,
      thunkAPI.getState().sharedReducer.selectedCountry
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<LocationListItemType>>(
      `${process.env.REACT_APP_API_URL}/location${queryString ? `?${queryString}` : ''}`,
      { signal: controller.signal }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching locations!');
  }
});

export const getLocationDetails = createAsyncThunk<LocationDetailsType, { id: number }, { state: RootState }>(
  'locations/getLocationDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<LocationDetailsType>(`${process.env.REACT_APP_API_URL}/location/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching location!');
    }
  }
);

export const patchLocationDetails = createAsyncThunk<
  LocationDetailsType,
  { id: number; data: UpdateLocationDto },
  { state: RootState }
>('locations/patchLocationDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<LocationDetailsType>(`${process.env.REACT_APP_API_URL}/location/${id}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating location!');
  }
});

export const deleteLocationDetails = createAsyncThunk<
  LocationDetailsType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('locations/deleteLocationDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<LocationDetailsType>(`${process.env.REACT_APP_API_URL}/location/${id}`);
    if (fetchList) thunkAPI.dispatch(getLocationsList(thunkAPI.getState().locationsReducer.locationsRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting location!');
  }
});

export const getLocationTelcoList = createAsyncThunk<
  {
    pbxs: Array<PBXListItemType>;
    dids: Array<DIDsListItemType>;
    pbxExtensions: Array<PbxExtensionListItemType>;
  },
  { id: number },
  { state: RootState }
>('locations/getLocationTelcoList', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<{
      pbxs: Array<PBXListItemType>;
      dids: Array<DIDsListItemType>;
      pbxExtensions: Array<PbxExtensionListItemType>;
    }>(`${process.env.REACT_APP_API_URL}/location/${id}/telco`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching telco information!');
  }
});

export const getLocationServicesList = createAsyncThunk<any, { id: number }, { state: RootState }>(
  'locations/getLocationServicesList',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/location/${id}/services`);
      return response.data?.services || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching services information!');
    }
  }
);

export const getLocationEquipmentList = createAsyncThunk<any, { id: number }, { state: RootState }>(
  'locations/getLocationEquipmentList',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/location/${id}/equipment`);
      return response.data || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching equipment information!');
    }
  }
);

export const getLocationConnectivityList = createAsyncThunk<any, { id: number }, { state: RootState }>(
  'locations/getLocationConnectivityList',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/location/${id}/connectivity`);
      return response.data || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching connectivity information!');
    }
  }
);

export const getLocationTicketsList = createAsyncThunk<any, { id: number }, { state: RootState }>(
  'locations/getLocationTicketsList',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/location/${id}/tickets`);
      return response.data?.tickets || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching tickets information!');
    }
  }
);

export const getLocationOrdersList = createAsyncThunk<any, { id: number }, { state: RootState }>(
  'locations/getLocationOrdersList',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/location/${id}/orders`);
      return response.data?.orders || [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching orders information!');
    }
  }
);

export const getImageURL = createAsyncThunk<
  string,
  { lat: string | number; lon: string | number },
  { state: RootState }
>('locations/getImageURL', async ({ lat, lon }, thunkAPI) => {
  try {
    const url = `https://maps.googleapis.com/maps/api/streetview?size=650x300&source=outdoor&location=${lat},${lon}&fov=120&key=AIzaSyBFaAEUCJ2EeyIRHcywd08JddGYK6Y56h0`;
    const instance = axios.create();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete instance.defaults.headers.common;
    await instance.get(url);
    return url;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching image!');
  }
});

export const postLocationInfoPanelDetails = createAsyncThunk<
  LocationPanelInfoType,
  { data: CreateLocationPanelInfoDto; id: number; type: string },
  { state: RootState }
>('locations/postLocationInfoPanelDetails', async ({ data, id, type }, thunkAPI) => {
  try {
    const response = await axios.patch<LocationPanelInfoType>(
      `${process.env.REACT_APP_API_URL}/location/${id}/locationInfo?type=${type}`,
      data
    );
    thunkAPI.dispatch(getLocationDetails({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating location panel info!');
  }
});

export const patchLocationInfoPanelDetails = createAsyncThunk<
  LocationPanelInfoType,
  { id: number; data: UpdateLocationPanelInfoDto; locationInfoID: number },
  { state: RootState }
>('locations/patchLocationInfoDetails', async ({ data, id, locationInfoID }, thunkAPI) => {
  try {
    const response = await axios.patch<LocationPanelInfoType>(
      `${process.env.REACT_APP_API_URL}/location/${id}/locationInfo/${locationInfoID}`,
      data
    );
    thunkAPI.dispatch(getLocationDetails({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating location panel info!');
  }
});

export const getPublicLocationDetails = createAsyncThunk<LocationDetailsType, { hash: string }, { state: RootState }>(
  'locations/getPublicLocationDetails',
  async ({ hash }, thunkAPI) => {
    try {
      const response = await axios.get<LocationDetailsType>(`${process.env.REACT_APP_API_URL}/location/public/${hash}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching location!');
    }
  }
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import { BaseCDRListItemType } from '../../types/cdr/base-cdr.type';
import { PaginationType } from '../../types/utils/pagination.type';
import axios from 'axios';
import { additionalQueryParams } from '../../utils/utilities';

export const getCDRSettings = createAsyncThunk<
  PaginationType<BaseCDRListItemType>,
  { org_id?: number; esi_id?: string; controller?: AbortController },
  { state: RootState }
>('cdrs/getCDRSettings/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs,
      thunkAPI.getState().sharedReducer.selectedCountry
    );
    const mergedParams = { ...params, ...additionalParams };

    if (mergedParams.org_id === undefined) {
      delete mergedParams.org_id;
    }

    const queryString = Object.keys(mergedParams)
      .map(key => `${key}=${encodeURIComponent(mergedParams[key])}`)
      .join('&');

    const response = await axios.get<PaginationType<BaseCDRListItemType>>(
      `${process.env.REACT_APP_API_URL}/cdrs/cdrSettings${queryString ? `?${queryString}` : ''}`,
      controller ? { signal: controller.signal } : undefined
    );

    return response.data;
  } catch (error: any) {
    console.error('API Error:', error.response?.data || error.message);
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Error fetching CDR settings!');
  }
});

export const enableCDRSettings = createAsyncThunk<
  void,
  { organization: { id: number }; externalSystemInstance: { id: number }; enableForSuborgs: boolean },
  {
    state: RootState;
  }
>('cdrs/enableCDRSettings', async (payload, thunkAPI) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/cdrs/enableCdrs`, payload);

    thunkAPI.dispatch(getCDRSettings({ org_id: payload.organization.id }));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error updating CDR settings!');
  }
});

export const deleteCDRSetting = createAsyncThunk<void, number, { state: RootState }>(
  'cdrs/deleteCDRSetting',
  async (id, thunkAPI) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/cdrs/cdrSettings/${id}`);
      return;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to delete CDR setting');
    }
  }
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import { additionalQueryParams } from '../../utils/utilities';
import axios from 'axios';
import { InventoryItemType } from '../../types/inventory-item/inventory-item.type';
import { setModalContent, setShowModal } from '../utils/utils.slice';

export const getInventoriesList = createAsyncThunk<
  PaginationType<InventoryItemType>,
  RouteParamsType & { controller?: AbortController },
  { state: RootState }
>('inventories/getInventoriesList/no-loader', async (data, thunkAPI) => {
  const { controller, ...params } = data;
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs,
      thunkAPI.getState().sharedReducer.selectedCountry
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<InventoryItemType>>(
      `${process.env.REACT_APP_API_URL}/inventory${queryString ? `?${queryString}` : ''}`,
      controller ? { signal: controller.signal } : undefined
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching inventory items!');
  }
});

export const getInventoriesStats = createAsyncThunk<unknown, { force: boolean }, { state: RootState }>(
  'inventories/getInventoriesStats',
  async ({ force }, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        null,
        thunkAPI.getState().sharedReducer.includeSuborgs,
        thunkAPI.getState().sharedReducer.selectedCountry
      );
      const mergedParams = { ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<unknown>(
        `${process.env.REACT_APP_API_URL}/stats/inventory${queryString ? `?${queryString}` : ''}&force=${force}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting Inventory Stats!');
    }
  }
);

export const postMoveInventories = createAsyncThunk<{ success: boolean }, { data: unknown }, { state: RootState }>(
  'tickets/postMoveInventories',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<{ success: boolean }>(`${process.env.REACT_APP_API_URL}/inventory/move`, data);

      thunkAPI.dispatch(getInventoriesList(thunkAPI.getState().inventoriesReducer.inventoriesRouteParams));
      thunkAPI.dispatch(setModalContent(null));
      thunkAPI.dispatch(setShowModal(false));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during moving inventory items!');
    }
  }
);

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncTaskType } from '../../types/async-task/async-task.type';
import { RootState } from '../../redux/Store';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams, history } from '../../utils/utilities';
import { setShowModal, setModalContent } from '../../features/utils/utils.slice';
import { CreateDIDDto, DIDDetailsType, DIDsListItemType, DIDType, UpdateDIDDto } from '../../types/did/did.type';
import { AddressType } from '../../types/address/address.type';
import { getDIDListPath } from '../../routes/Router';

export const postDIDs = createAsyncThunk<
  AsyncTaskType,
  {
    data: {
      country: { id: number };
      pbx: { id: number };
      dids: Array<Pick<DIDType, 'did' | 'description' | 'did_authid' | 'did_password' | 'did_username'>>;
    };
  },
  { state: RootState }
>('dids/postDIDs', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<AsyncTaskType>(`${process.env.REACT_APP_API_URL}/dids/bulk`, data);
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating DIDs!');
  }
});

export const postDIDDetails = createAsyncThunk<
  DIDDetailsType,
  { data: CreateDIDDto; useOld?: boolean },
  {
    state: RootState;
  }
>('dids/postDIDDetails', async ({ data, useOld = false }, thunkAPI) => {
  try {
    const response = await axios.post<DIDDetailsType>(
      `${process.env.REACT_APP_API_URL}/dids${useOld ? '/old' : ''}`,
      data
    );
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating DID!');
  }
});

export const getDIDsList = createAsyncThunk<
  PaginationType<DIDsListItemType>,
  RouteParamsType & {
    controller: AbortController;
  },
  { state: RootState }
>('dids/getDIDsList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs,
      thunkAPI.getState().sharedReducer.selectedCountry
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<DIDsListItemType>>(
      `${process.env.REACT_APP_API_URL}/dids${queryString ? `?${queryString}` : ''}`,
      { signal: controller.signal }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching DIDs!');
  }
});

export const getDIDDetails = createAsyncThunk<DIDDetailsType, { id: number }, { state: RootState }>(
  'dids/getDIDDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<DIDDetailsType>(`${process.env.REACT_APP_API_URL}/dids/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching DID!');
    }
  }
);

export const patchDIDDetails = createAsyncThunk<
  DIDDetailsType,
  { id: number; data: UpdateDIDDto; disableHistory?: boolean },
  { state: RootState }
>('dids/patchDIDDetails', async ({ data, disableHistory, id }, thunkAPI) => {
  try {
    const response = await axios.patch<DIDDetailsType>(`${process.env.REACT_APP_API_URL}/dids/${id}`, data);
    setTimeout(() => {
      if (!disableHistory) {
        history.push(getDIDListPath);
      }
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating DID!');
  }
});

export const deleteDIDDetails = createAsyncThunk<
  DIDDetailsType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('dids/deleteDIDDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<DIDDetailsType>(`${process.env.REACT_APP_API_URL}/dids/${id}`);
    if (fetchList) thunkAPI.dispatch(getDIDsList(thunkAPI.getState().didsReducer.didsRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting DID!');
  }
});

// DEPRECATED
export const postDIDFixRouting = createAsyncThunk<{ success: boolean }, { id: number }, { state: RootState }>(
  'dids/postDIDFixRouting',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.post<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/dids/${id}/route`, {});
      thunkAPI.dispatch(getDIDDetails({ id }));
      thunkAPI.dispatch(getDIDRouting({ id }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fixing DID routing!');
    }
  }
);

export const getDIDRouting = createAsyncThunk<unknown, { id: number }, { state: RootState }>(
  'dids/getDIDRouting',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<unknown>(`${process.env.REACT_APP_API_URL}/dids/${id}/routing_status`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting DID Routing!');
    }
  }
);

export const getDIDAvailability = createAsyncThunk<
  { dids: Array<{ object: DIDType; did: string }>; available: boolean },
  { dids: string },
  {
    state: RootState;
  }
>('dids/getDIDAvailability', async ({ dids }, thunkAPI) => {
  try {
    const response = await axios.get<{ dids: Array<{ object: DIDType; did: string }>; available: boolean }>(
      `${process.env.REACT_APP_API_URL}/dids/checkAvailability?dids=${dids}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting DIDs availability!');
  }
});

// DEPRECATED
export const postTemporaryDID = createAsyncThunk<{ success: boolean }, { id: number }, { state: RootState }>(
  'dids/postTemporaryDID',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.post<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/dids/${id}/temporary`, {});
      thunkAPI.dispatch(getDIDsList(thunkAPI.getState().didsReducer.didsRouteParams));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating temporary DID!');
    }
  }
);

export const patchDIDCnam = createAsyncThunk<
  { success: boolean },
  { id: number; data: { cnam: string } },
  { state: RootState }
>('dids/patchDIDCnam', async ({ id, data }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/dids/${id}/cnam`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing DID!');
  }
});

export const patchDIDE911 = createAsyncThunk<
  { success: boolean },
  { id: number; data: AddressType },
  { state: RootState }
>('dids/patchDIDE911', async ({ id, data }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/dids/${id}/addresse911`, data);
    thunkAPI.dispatch(getProvisionDIDE911({ id, isCustomAddress: true }));
    thunkAPI.dispatch(getDIDDetails({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during Overwritten E911!');
  }
});

export const getVerifyDIDE911 = createAsyncThunk<{ success: boolean }, { id: number }, { state: RootState }>(
  'dids/getVerifyDIDE911',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/dids/${id}/verify-e911`);
      thunkAPI.dispatch(getDIDDetails({ id }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during Provision E911!');
    }
  }
);

export const getProvisionDIDE911 = createAsyncThunk<
  { success: boolean },
  { id: number; isCustomAddress: boolean },
  { state: RootState }
>('dids/getProvisionDIDE911', async ({ id, isCustomAddress = false }, thunkAPI) => {
  try {
    const query = isCustomAddress ? '?custom_address=true' : '';
    const url = `${process.env.REACT_APP_API_URL}/dids/${id}/provision-e911${query}`;

    const response = await axios.get<{ success: boolean }>(url);
    thunkAPI.dispatch(getDIDDetails({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during Provision E911!');
  }
});

export const postPBXExtensionDID = createAsyncThunk<
  { success: boolean },
  {
    id: number;
    data: {
      pbx_extension?: { id: number };
      pbx_extension_type?: { id: number };
      disconnect?: boolean;
    };
  },
  { state: RootState }
>('dids/postPBXExtensionDID', async ({ id, data }, thunkAPI) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/dids/${id}/pbxExtension`;

    if (data.pbx_extension) {
      url = `${process.env.REACT_APP_API_URL}/dids/${id}/pbxExtension`;
    }

    if (data.pbx_extension_type) {
      url = `${process.env.REACT_APP_API_URL}/dids/${id}/createExtension`;
    }

    if (data.disconnect) {
      url = `${process.env.REACT_APP_API_URL}/dids/${id}/disconnectPbxExtension`;
    }

    const response = await axios.post<{ success: boolean }>(url, data);
    thunkAPI.dispatch(getDIDDetails({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during assigning DID to PBX Extension!');
  }
});
